@use "sass:color";
@use "@theme/scss/utils/variables" as theme;
@use "@/scss/utils/variables" as base;

@mixin hook-badge() {

  &.tm-cart {
    color: base.$color-tertiary !important;
    background: base.$color-secondary;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

// Icon
@mixin hook-icon-button-misc() {

  .tm-icon-button-dark {
    color: base.$color-white;
    background: base.$color-tertiary;
  }
}

@mixin hook-icon-button-hover() {

  &.tm-icon-button-dark {
    color: base.$color-white;
    background: color.adjust(base.$color-tertiary, $lightness: 30%);
  }
}

// Marker
@mixin hook-marker() {
  background: unset;
  transition: all .3s ease-in;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    color: base.$color-secondary;
    background: base.$color-secondary content-box;
    padding: 10px;
    border: 2px solid base.$color-secondary;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }

  &.tm-hover-large:hover {
    transform-origin: center center;
    transform: scale(1.6, 1.6) translate(-30%, -30%);
  }

  &.tm-hover-large[aria-expanded="true"] {
    transform-origin: center center;
    transform: scale(1.6, 1.6) translate(-30%, -30%);
  }
}

// Nav


// Navbar
@mixin hook-navbar-container() {
  position: relative;
}

@mixin hook-navbar-nav-item() {
  text-transform: none;
  font-weight: base.$font-weight-medium;

  @media (max-width: $breakpoint-small-max) {
    min-height: 70px !important;
    height: 70px !important;
  }

  > span {
    border-bottom: 1px solid transparent;
    line-height: 1.1rem;
  }

  &.tm-nav-shop > span {
    background: base.$color-secondary;
    border-radius: 20px;
    padding: 3px 15px;
  }
}

@mixin hook-navbar-misc() {

  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    @media (max-width: $breakpoint-small-max) {
      min-height: 70px !important;
      height: 70px !important;
    }
  }
}

@mixin hook-navbar-nav-item-hover() {

  > span {
    border-bottom: 1px solid base.$color-tertiary;
  }

  &.tm-nav-shop > span {
    border-bottom-color: transparent;
  }
}

@mixin hook-navbar-nav-item-active() {

  > span {
    border-bottom: 1px solid base.$color-tertiary;
  }

  &.tm-nav-shop > span {
    border-bottom-color: transparent;
  }
}

// Section
@mixin hook-section-secondary() {

  &.tm-section-footer {
    background: linear-gradient(90deg, base.$color-secondary 50%, theme.$color-primary-dark, theme.$color-primary-dark);
  }
}

@mixin hook-text-misc() {

  .uk-text-medium {
    font-weight: base.$font-weight-medium !important;
  }
}
