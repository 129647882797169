@use "sass:color";
@use "@/scss/utils/variables" as base;
@use "@/scss/base/uikit/uikit-variables" as uikit;

// Custom Image Placeholder
img[data-src][src*="data:image"] {
  background: rgba(0 0 0 / 5%);
}

.grecaptcha-badge {
  display: none;
}

p a:not(.uk-link, .uk-button) {
  text-decoration: underline;

  &:hover:not(.uk-accordion-title),
  &:focus:not(.uk-accordion-title),
  &:active:not(.uk-accordion-title) {
    color: base.$color-global;
  }
}

p {
  @media (max-width: uikit.$breakpoint-medium-max) {
    margin-bottom: 10px;
  }
}

ul ul {
  list-style-type: disc;
}

.tm-shop-navigation {
  z-index: 2;
}

.tm-shop-checkout {

  .uk-active .tm-icon-button-dark {
    background: base.$color-secondary;
    color: base.$color-tertiary;
  }
}

.tm-align-end {
  align-content: flex-end;
}

.tm-align-center {
  align-content: center;
}

@media (min-width: uikit.$breakpoint-small) {

  .tm-width-category {
    max-width: 200px;
    min-width: 200px;
  }

  .cc-revoke.cc-bottom {
    right: 3em;
    left: unset !important;
  }
}

/* stylelint-disable */
.cc-revoke.cc-bot {

  @media (max-width: 414px) {
    right: unset !important;
  }
}
/* stylelint-enable */
