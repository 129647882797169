@charset "UTF-8";
/* stylelint-disable declaration-colon-space-after */
@font-face {
  font-family: "Roc Grotesk";
  src: url("@/fonts/923e16a8-c57d-4f97-92d8-0d4aca56b180.woff2") format("woff2"), url("@/fonts/ade876eb-5c42-4c95-9ce8-1641a11d556d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Roc Grotesk";
  src: url("@/fonts/1bc155e8-cd20-49ce-8eb8-1bcea5378124.woff2") format("woff2"), url("@/fonts/1b2ef3e5-b107-4790-bee2-6cda85dc3231.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Roc Grotesk";
  src: url("@/fonts/69eaf6d4-d982-4623-b025-832b8aa7038b.woff2") format("woff2"), url("@/fonts/0c5abe0a-966b-4ab2-bc39-62d76c90c2a6.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: normal;
  line-height: 1.667;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #000;
  text-decoration: none;
  text-decoration: underline;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.889rem;
  /* 3 */
  color: #f00000;
  white-space: nowrap;
  padding: 2px 6px;
  background: hsl(0, 0%, 125.8774509804%);
}

/*
 * Emphasize
 */
em {
  color: #f00000;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #000;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #000;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge,
.uk-heading-3xlarge {
  margin: 0 0 20px 0;
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  color: #000;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge,
* + .uk-heading-3xlarge {
  margin-top: 40px;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 1.5556rem;
  line-height: 1.2222;
}

h2, .uk-h2 {
  font-size: 1.111rem;
  line-height: 1.375;
}

h3, .uk-h3 {
  font-size: 1.111rem;
  line-height: 1.3333;
  font-weight: 700;
}

h4, .uk-h4 {
  font-size: 1.111rem;
  line-height: 1.4;
  font-weight: 700;
}

h5, .uk-h5 {
  font-size: 18px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.889rem;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.5rem;
  }
  h2, .uk-h2 {
    font-size: 1.778rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 40px 0;
  border: 0;
  border-top: 1px solid #000;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 40px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.111rem;
  line-height: 1.5;
  font-style: italic;
  color: #575757;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.889rem;
  line-height: 1.5;
  color: #000;
}
blockquote footer::before {
  content: "— ";
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.889rem / 1.5 Consolas, monaco, monospace;
  color: #000;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background: #fff;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Focus
 ========================================================================== */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px dotted #575757;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main { /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px;
}

@media (min-width: 960px) {
  h3,
  .uk-h3 {
    font-size: 1.667rem;
  }
}
/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a,
.uk-link-toggle .uk-link-muted {
  color: #e2e2e2;
}

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted {
  color: #000;
}

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a,
.uk-link-toggle .uk-link-text {
  color: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6rem;
}
a.uk-link-text::before,
.uk-link-text a::before,
.uk-link-toggle .uk-link-text::before {
  content: "";
}

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text {
  color: #000;
  position: relative;
  text-decoration: none;
}
a.uk-link-text:hover::before,
a.uk-link-text:hover > .uk-text-link::before,
.uk-link-text a:hover::before,
.uk-link-text a:hover > .uk-text-link::before,
.uk-link-toggle:hover .uk-link-text::before,
.uk-link-toggle:hover .uk-link-text > .uk-text-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  background-color: #000;
  transition: all 0.3s ease-out 0s;
}

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a,
.uk-link-toggle .uk-link-heading {
  color: inherit;
}

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading {
  color: #34382e;
  text-decoration: none;
}

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important;
}

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important;
}

.uk-link-toggle:hover > .tm-icon-toggle {
  background: #e2e2e2;
}

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2;
}

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1;
}

.uk-heading-large {
  font-size: 2rem;
  line-height: 4.72rem;
  font-weight: 500;
}
@media (max-width: 959px) {
  .uk-heading-large {
    line-height: 3rem;
  }
}

.uk-heading-xlarge {
  font-size: 3rem;
  line-height: 1;
}

.uk-heading-2xlarge {
  font-size: 4.45rem;
  line-height: 1;
}

.uk-heading-3xlarge {
  font-size: 8rem;
  line-height: 1;
}

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem;
  }
  .uk-heading-medium {
    font-size: 3.5rem;
  }
  .uk-heading-large {
    font-size: 3rem;
  }
  .uk-heading-xlarge {
    font-size: 4.45rem;
  }
  .uk-heading-2xlarge {
    font-size: 8rem;
  }
  .uk-heading-3xlarge {
    font-size: 11rem;
  }
}
/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem;
  }
  .uk-heading-large {
    font-size: 4.45rem;
  }
  .uk-heading-xlarge {
    font-size: 8rem;
  }
  .uk-heading-2xlarge {
    font-size: 11rem;
  }
  .uk-heading-3xlarge {
    font-size: 15rem;
  }
}
/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #f0f0f0;
}

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative;
}

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #f0f0f0;
}

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden;
}

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative;
}

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #f0f0f0;
}

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em);
}

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em);
}

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*=uk-divider] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + [class*=uk-divider] {
  margin-top: 20px;
}

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23f0f0f0%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #f0f0f0;
}

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%;
}

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%;
}

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0;
}

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #f0f0f0;
  vertical-align: top;
}

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: max-content;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #f0f0f0;
}

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}

/*
 * Avoid column break within the list item, when using `column-count`
 */
.uk-list > * {
  break-inside: avoid-column;
}

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0;
}

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px;
}

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px;
}

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal;
}

.uk-list-decimal > * {
  counter-increment: decimal;
}

.uk-list-disc > ::before,
.uk-list-circle > ::before,
.uk-list-square > ::before,
.uk-list-decimal > ::before,
.uk-list-hyphen > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.667em;
  margin-bottom: -1.667em;
  display: list-item;
  list-style-position: inside;
  text-align: right;
}

.uk-list-disc > ::before {
  list-style-type: disc;
}

.uk-list-circle > ::before {
  list-style-type: circle;
}

.uk-list-square > ::before {
  list-style-type: square;
}

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . ";
}

.uk-list-hyphen > ::before {
  content: "–  ";
}

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #e2e2e2 !important;
}

.uk-list-emphasis > ::before {
  color: #575757 !important;
}

.uk-list-primary > ::before {
  color: #34382e !important;
}

.uk-list-secondary > ::before {
  color: #d6da2d !important;
}

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px;
}

.uk-list-bullet > ::before {
  content: "";
  display: list-item;
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.667em;
  margin-bottom: -1.667em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23000%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #000;
}
.uk-list-divider > :nth-child(n+2).tm-downloads {
  margin-top: 20px !important;
  padding-top: 20px !important;
  border-color: #f0f0f0 !important;
  border-width: 2px !important;
}

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px;
}
.uk-list-striped > *:nth-of-type(odd) {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.uk-list-striped > :nth-of-type(odd) {
  background: hsl(0, 0%, 125.8774509804%);
}

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px;
}

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0;
}

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 30px;
  padding-top: 30px;
}

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0;
}

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px;
}

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0;
}

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

.tm-list-xlarge > :nth-child(n+2) {
  margin-top: 60px;
  padding-top: 60px;
}
@media (max-width: 959px) {
  .tm-list-xlarge > :nth-child(n+2) {
    margin-top: 30px;
    padding-top: 30px;
    margin-bottom: 0;
  }
}

/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #e2e2e2;
  /* 1 */
  text-decoration: none !important;
}

.uk-icon-link:hover {
  color: #000;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: black;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f0f0f0;
  color: #000;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.uk-icon-button .tm-icon-dark {
  background: #000;
  color: #fff;
}
.uk-icon-button.tm-icon-light {
  background: #fff;
  color: #000;
}
.uk-icon-button.tm-icon-primary {
  background: #0d616f;
  color: #fff;
}
.uk-icon-button.tm-icon-button-dark {
  color: #fff;
  background: #000;
}

/* Hover */
.uk-icon-button:hover {
  background-color: rgb(227.25, 227.25, 227.25);
  color: #000;
}
.uk-icon-button:hover.tm-icon-button-dark {
  color: #fff;
  background: rgb(76.5, 76.5, 76.5);
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: rgb(214.5, 214.5, 214.5);
  color: #000;
}

.uk-icon-button-large {
  width: 42px;
  height: 42px;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 * 1. Reset `min-width`
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
  /* 1 */
  min-width: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #fff;
  color: #000;
  font-size: 0.889rem;
  border: 1px solid #cbcbcb;
  margin-top: 10px;
}
.uk-input:valid:not(.tm-shop-select),
.uk-select:valid:not(.tm-shop-select),
.uk-textarea:valid:not(.tm-shop-select) {
  border: 1px solid #000;
}
.uk-input::placeholder,
.uk-select::placeholder,
.uk-textarea::placeholder {
  color: black;
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 50px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
  padding: 0 15px;
}
.uk-input::placeholder,
.uk-select:not([multiple]):not([size])::placeholder {
  color: black;
}

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 48px;
}

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: top;
}

.uk-select[multiple],
.uk-select[size] {
  resize: vertical;
}

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: hsl(0, 0%, 125.8774509804%);
  color: #e2e2e2;
  border-color: #f0f0f0;
}

/*
 * Placeholder
 */
.uk-input::placeholder {
  color: #e2e2e2;
}

.uk-textarea::placeholder {
  color: #e2e2e2;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.889rem;
}

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 28px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.111rem;
}

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 53px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f00000;
  border-color: #f00000;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32cd32;
  border-color: #32cd32;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
  border-color: transparent;
}

.uk-form-blank:focus {
  border-color: #f0f0f0;
  border-style: solid;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size]) option {
  color: #000;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23e2e2e2%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23e2e2e2%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23000%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Radio and checkbox
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: transparent;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid rgb(214.5, 214.5, 214.5);
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border-color: #34382e;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #34382e;
  border-color: transparent;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #1a1c17;
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: hsl(0, 0%, 125.8774509804%);
  border-color: #f0f0f0;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23e2e2e2%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23e2e2e2%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23e2e2e2%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 0.889rem;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 10px;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
.uk-form-label {
  color: #0d616f;
  font-weight: 700;
  font-size: 0.889rem;
}
.uk-form-label.required::after {
  content: "*";
}

/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 5px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 5px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 50px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #e2e2e2;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #000;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 50px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input {
  padding-right: 50px !important;
}

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 40px;
  vertical-align: middle;
  font-size: 0.889rem;
  /* 8 */
  line-height: 38px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
  border-radius: 20px;
  text-transform: none;
  font-weight: 500;
}

.uk-button:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none;
}

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f0f0f0;
  color: #000;
  border: unset;
}

/* Hover */
.uk-button-default:hover {
  background-color: #e2e2e2;
  color: #000;
  border-color: #bdbdbd;
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #e2e2e2;
  color: #575757;
  border-color: rgb(163.5, 163.5, 163.5);
}

/*
 * Primary
 */
.uk-button-primary {
  background-color: #000;
  color: #fff;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-primary:hover {
  background-color: #575757;
  color: #fff;
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #575757;
  color: #fff;
}

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-secondary:hover {
  background-color: #e2e2e2;
  color: #000;
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #e2e2e2;
  color: #000;
}

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f00000;
  color: #fff;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-danger:hover {
  background-color: rgb(214.5, 0, 0);
  color: #fff;
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #bd0000;
  color: #fff;
}

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #575757;
  color: #a5a5a5;
  border-color: #f0f0f0;
}

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 30px;
  line-height: 28px;
  font-size: 0.889rem;
}

.uk-button-large {
  padding: 0 50px;
  line-height: 53px;
  font-size: 0.889rem;
}

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.667;
  background: none;
  /* 2 */
  color: #575757;
  position: relative;
}
.uk-button-text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  border-bottom: 1px solid currentColor;
  transition: right 0.3s ease-out;
}

/* Hover */
.uk-button-text:hover {
  color: #575757;
}
.uk-button-text:hover::before {
  right: 0;
}

/* Disabled */
.uk-button-text:disabled {
  color: #e2e2e2;
}
.uk-button-text:disabled::before {
  display: none;
}

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.667;
  background: none;
  /* 2 */
  color: #575757;
}

/* Hover */
.uk-button-link:hover {
  color: #e2e2e2;
  text-decoration: none;
}

/* Disabled */
.uk-button-link:disabled {
  color: #e2e2e2;
  text-decoration: none;
}

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}

/* Group
 ========================================================================== */
/*
 * Collapse border
 */
.uk-button-group > .uk-button:nth-child(n+2),
.uk-button-group > div:nth-child(n+2) .uk-button {
  margin-left: -1px;
}

/*
 * Create position context to superimpose the successor elements border
 * Known issue: If you use an `a` element as button and an icon inside,
 * the active state will not work if you click the icon inside the button
 * Workaround: Just use a `button` or `input` element as button
 */
.uk-button-group .uk-button:hover,
.uk-button-group .uk-button:focus,
.uk-button-group .uk-button:active,
.uk-button-group .uk-button.uk-active {
  position: relative;
  z-index: 1;
}

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in all browsers.
 * 2. Behave like a block element.
 * 3. Remove borders in Firefox.
 * 4. Remove default style in Chrome, Safari and Edge.
 * 5. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  display: block;
  width: 100%;
  /* 3 */
  border: 0;
  /* 4 */
  background-color: hsl(0, 0%, 125.8774509804%);
  /* 5 */
  margin-bottom: 20px;
  height: 15px;
  border-radius: 500px;
  overflow: hidden;
}

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px;
}

/*
 * Show background color set on `uk-progress` in Chrome, Safari and Edge.
 */
.uk-progress::-webkit-progress-bar {
  background-color: transparent;
}

/*
 * Progress Bar
 * 1. Transitions don't work on `::-moz-progress-bar` pseudo element in Firefox yet.
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=662351
 */
.uk-progress::-webkit-progress-value {
  background-color: #34382e;
  transition: width 0.6s ease;
}

.uk-progress::-moz-progress-bar {
  background-color: #34382e;
  /* 1 */
  transition: width 0.6s ease;
}

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  display: flow-root;
  box-sizing: border-box; /* 1 */
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*
 * Large
 */
.uk-section-large {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  --uk-inverse: dark;
  background: #fff;
}

/*
 * Muted
 */
.uk-section-muted {
  --uk-inverse: dark;
  background: hsl(0, 0%, 125.8774509804%);
}

/*
 * Primary
 */
.uk-section-primary {
  --uk-inverse: light;
  background: #34382e;
}

/*
 * Secondary
 */
.uk-section-secondary {
  --uk-inverse: dark;
  background: #d6da2d;
}
.uk-section-secondary.tm-section-footer {
  background: linear-gradient(90deg, #d6da2d 50%, #34382e, #34382e);
}

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
@media (max-width: 1199px) {
  .tm-section-footer {
    background: #fff !important;
  }
  .tm-section-footer .uk-container {
    padding: 0;
  }
  .tm-section-footer .uk-container > .uk-grid > div {
    margin: 0 !important;
  }
  .tm-section-footer .uk-container > .uk-grid > div > div {
    padding: 30px;
  }
}

/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 50px;
    padding-right: 50px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small {
  max-width: 1000px;
}

.uk-container-large {
  max-width: 1620px;
}

.uk-container-xlarge {
  max-width: 1820px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (1000px / 2) - 40px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 50px);
  }
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 50px);
  }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (1000px / 2) - 50px);
  }
  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1620px / 2) - 50px);
  }
  .uk-container-expand-left.uk-container-xlarge,
  .uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1820px / 2) - 50px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}

.uk-container-item-padding-remove-left {
  margin-left: -15px;
}

.uk-container-item-padding-remove-right {
  margin-right: -15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 50px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -50px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -50px;
  }
}
.uk-container-medium {
  max-width: 1420px;
}

.tm-margin-left {
  margin-left: 200px;
}
@media (max-width: 1199px) {
  .tm-margin-left {
    margin-left: 0;
  }
}
@media (max-width: 959px) {
  .tm-margin-left {
    margin-left: 0;
  }
}

.tm-margin-right {
  margin-right: 200px;
}
@media (max-width: 1199px) {
  .tm-margin-right {
    margin-right: 0;
  }
}
@media (max-width: 959px) {
  .tm-margin-right {
    margin-right: 0;
  }
}

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box;
}
.uk-card.tm-card-search {
  border: 1px solid #a5a5a5;
  background: #fff;
}
.uk-card .tm-card-icon {
  color: #d6da2d;
}

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 40px 40px;
}

.uk-card-header {
  display: flow-root;
  padding: 20px 40px;
}

.uk-card-footer {
  display: flow-root;
  padding: 20px 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 30px 30px;
  }
  .uk-card-header {
    padding: 25px 50px;
  }
  .uk-card-footer {
    padding: 25px 50px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0;
}

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
[class*=uk-card-media] .tm-card-overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%) 0 0 no-repeat padding-box;
  opacity: 0.6;
}

/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.111rem;
  line-height: 1.4;
}
.uk-card-title * {
  font-weight: 700 !important;
}

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #000;
  color: #fff;
  font-size: 0.889rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  right: unset;
  left: 20px;
  font-weight: 500;
  border-radius: 20px;
  z-index: 0;
}

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0;
}

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #fff;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  --uk-inverse: dark;
  background-color: #f0f0f0;
  color: #000;
  box-shadow: none;
}

.uk-card-default .uk-card-title {
  color: #0d616f;
}

.uk-card-default.uk-card-hover:hover {
  background-color: #f0f0f0;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-card-default .uk-card-header {
  border-bottom: 1px solid #f0f0f0;
}

.uk-card-default .uk-card-footer {
  border-top: 1px solid #f0f0f0;
}

/*
 * Primary
 */
.uk-card-primary {
  --uk-inverse: light;
  background-color: #34382e;
  color: #fff;
}
.uk-card-primary .uk-card-title {
  color: #d6da2d !important;
}

.uk-card-primary .uk-card-title {
  color: #d6da2d;
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #34382e;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/*
 * Secondary
 */
.uk-card-secondary {
  --uk-inverse: dark;
  background-color: #d6da2d;
  color: #000;
}
.uk-card-secondary .tm-card-icon {
  color: #0d616f;
}

.uk-card-secondary .uk-card-title {
  color: #0d616f;
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #d6da2d;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px;
}

.uk-card-small .uk-card-header {
  padding: 13px 20px;
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
  .uk-card-large .uk-card-body {
    padding: 80px 80px;
  }
  .uk-card-large .uk-card-header {
    padding: 40px 80px;
  }
  .uk-card-large .uk-card-footer {
    padding: 40px 80px;
  }
}
.tm-product-card h3 > span {
  font-size: 1.111rem !important;
}

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #000;
  transition: 0.2s ease-in-out;
  transition-property: color, opacity;
}
.uk-close:not(.uk-lightbox-toolbar-icon) {
  background: #fff;
}

/* Hover */
.uk-close:hover {
  color: #000;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #d6da2d;
  color: #fff;
  background: unset;
  transition: all 0.3s ease-in;
}
.uk-marker::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  color: #d6da2d;
  background: #d6da2d content-box;
  padding: 10px;
  border: 2px solid #d6da2d;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.uk-marker.tm-hover-large:hover {
  transform-origin: center center;
  transform: scale(1.6, 1.6) translate(-30%, -30%);
}
.uk-marker.tm-hover-large[aria-expanded=true] {
  transform-origin: center center;
  transform: scale(1.6, 1.6) translate(-30%, -30%);
}

/* Hover */
.uk-marker:hover {
  color: #fff;
}

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: hsl(0, 0%, 125.8774509804%);
  color: #000;
}

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px;
  color: inherit;
  opacity: 0.4;
}

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
.uk-alert-close:hover {
  color: inherit;
  opacity: 0.8;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: rgb(183.95, 185.35, 181.85);
  color: #34382e;
}

/*
 * Success
 */
.uk-alert-success {
  background: rgb(234.5, 250, 234.5);
  color: #32cd32;
}

/*
 * Warning
 */
.uk-alert-warning {
  background: rgb(255, 232.5, 191.25);
  color: #ffa500;
}

/*
 * Danger
 */
.uk-alert-danger {
  background: rgb(251.6666666667, 198.3333333333, 198.3333333333);
  color: #f00000;
}

/*
 * Content
 */
.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
  color: inherit;
}

.uk-alert a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

.uk-alert a:not([class]):hover {
  color: inherit;
  text-decoration: underline;
}

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 40px 40px;
}

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0;
}

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  --uk-inverse: dark;
  background: rgba(0, 0, 0, 0.5);
}

/*
 * Primary
 */
.uk-overlay-primary {
  --uk-inverse: light;
  background: rgba(214, 218, 45, 0.8);
}

/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1;
}

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #000;
}

.uk-search-input:focus {
  outline: none;
}

/* Placeholder */
.uk-search-input::placeholder {
  color: #e2e2e2;
}

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #e2e2e2;
}

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #e2e2e2;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 240px;
}

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;
  border: 1px solid #f0f0f0;
}

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #34382e;
}

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px;
}

.uk-search-default:has(.uk-search-icon:not(.uk-search-icon-flip)) .uk-search-input {
  padding-left: 40px;
}

.uk-search-default:has(.uk-search-icon-flip) .uk-search-input {
  padding-right: 40px;
}

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 240px;
}

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  border: 1px solid #f0f0f0;
}

/* Focus */
.uk-search-navbar .uk-search-input:focus {
  background-color: #fff;
  border-color: #34382e;
}

/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px;
}

.uk-search-navbar:has(.uk-search-icon:not(.uk-search-icon-flip)) .uk-search-input {
  padding-left: 40px;
}

.uk-search-navbar:has(.uk-search-icon-flip) .uk-search-input {
  padding-right: 40px;
}

/* Medium modifier
 ========================================================================== */
.uk-search-medium {
  width: 400px;
}

/*
 * Input
 */
.uk-search-medium .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.222rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-medium .uk-search-icon {
  width: 24px;
}

.uk-search-medium:has(.uk-search-icon:not(.uk-search-icon-flip)) .uk-search-input {
  padding-left: 34px;
}

.uk-search-medium:has(.uk-search-icon-flip) .uk-search-input {
  padding-right: 34px;
}

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px;
}

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 1.778rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 40px;
}

.uk-search-large:has(.uk-search-icon:not(.uk-search-icon-flip)) .uk-search-input {
  padding-left: 60px;
}

.uk-search-large:has(.uk-search-icon-flip) .uk-search-input {
  padding-right: 60px;
}

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #e2e2e2;
}

/* Hover */
.uk-search-toggle:hover {
  color: #000;
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none;
}
.uk-accordion .uk-open a {
  border: unset;
}
.uk-accordion .uk-open .uk-accordion-content {
  border-bottom: 1px solid #000;
  padding-bottom: 40px;
}

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px;
  margin: 0;
}

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 0.889rem;
  line-height: 1.4;
  color: #0d616f;
  border-bottom: 1px solid black;
  font-weight: 700;
  padding: 20px 0;
}
.uk-accordion-title::before {
  content: "";
  width: 20px;
  height: 20px;
  margin-left: 10px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik00ODAtMzMzIDI0MC01NzNsNTEtNTEgMTg5IDE4OSAxODktMTg5IDUxIDUxLTI0MCAyNDBaIi8+PC9zdmc+");
}
.uk-open > .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Im0yOTEtMzM2LTUxLTUxIDI0MC0yNDAgMjQwIDI0MC01MSA1MS0xODktMTg5LTE4OSAxODlaIi8+PC9zdmc+");
}

/* Hover */
.uk-accordion-title:hover {
  color: #0d616f;
  text-decoration: none;
  border-bottom: 1px solid black;
}

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 20px;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  width: 300px;
}

/* Show */
.uk-drop.uk-open {
  display: block;
}

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important;
}

/* Parent icon
 ========================================================================== */
.uk-drop-parent-icon {
  margin-left: 0.25em;
  transition: transform 0.3s ease-out;
}

[aria-expanded=true] > .uk-drop-parent-icon {
  transform: rotateX(180deg);
}

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * Adopts `uk-drop`
 * 1. Reset drop and let text expand the width instead of wrapping
 * 2. Set a default width
 * 3. Style
 */
.uk-dropdown {
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 15px;
  --uk-inverse: dark;
  /* 1 */
  width: auto;
  /* 2 */
  min-width: 200px;
  /* 3 */
  padding: 25px;
  background: #fff;
  color: #000;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

/*
 * Remove margin from the last-child
 */
.uk-dropdown > :last-child {
  margin-bottom: 0;
}

.uk-dropdown :focus-visible {
  outline-color: #575757 !important;
}

/* Size modifier
 ========================================================================== */
.uk-dropdown-large {
  padding: 40px;
}

/* Dropbar modifier
 ========================================================================== */
/*
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 5px 0 25px 0;
  --uk-position-viewport-offset: 15px;
  box-shadow: none;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-dropdown-dropbar {
    --uk-position-viewport-offset: 40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-dropdown-dropbar {
    --uk-position-viewport-offset: 50px;
  }
}
.uk-dropdown-dropbar-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  font-size: 0.889rem;
}

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #000;
}

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #000;
  font-weight: 700;
}

/*
 * Subtitle
 */
.uk-dropdown-nav .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #575757;
}

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #f0f0f0;
}

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #e2e2e2;
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #000;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 50px;
    padding-right: 50px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 800px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 20px 20px;
}

.uk-modal-header {
  display: flow-root;
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.uk-modal-footer {
  display: flow-root;
  padding: 10px 20px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal-body {
    padding: 80px 100px;
  }
  .uk-modal-header {
    padding: 20px 40px;
  }
  .uk-modal-footer {
    padding: 20px 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 1.667rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 20px;
  right: 20px;
  padding: 5px;
  background: #f0f0f0 !important;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
.uk-modal-close-full {
  top: 0;
  right: 0;
  padding: 10px;
  background: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal-close-full {
    padding: 20px;
  }
}
/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y;
}

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
}

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none;
}

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Container
 ========================================================================== */
/*
 * 1. Clip child elements
 * 2. Prevent accidental scrolling through elements in slide getting focused
 */
.uk-slider-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  overflow: clip;
}

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px;
}

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y;
}

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none;
}

.uk-slider-items.uk-grid {
  flex-wrap: nowrap;
}

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 2. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none !important;
  box-sizing: border-box;
  max-width: 100%;
  /* 2 */
  position: relative;
}

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. Create stacking context already when not sticky to have the same context
*     for position set to `sticky` and `relative`
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 980;
  /* 3 */
  box-sizing: border-box;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*
 * Faster animations
 */
.uk-sticky[class*=uk-animation-] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  --uk-inverse: dark;
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #d6da2d;
  /* 3 */
  overflow-y: auto;
}
@media (max-width: 960px) {
  .uk-offcanvas-bar.tm-main {
    width: 100%;
  }
  .uk-offcanvas-bar.tm-main::before, .uk-offcanvas-bar.tm-main::after {
    content: unset;
  }
}
@media (min-width: 960px) {
  .uk-offcanvas-bar.tm-main {
    top: 100px;
  }
}
.uk-offcanvas-bar.tm-shop-navigation {
  top: 100px;
}
.uk-offcanvas-bar.tm-mobile-navigation::before {
  background: #fff;
  height: 70px;
  width: 256px;
}
.uk-offcanvas-bar.tm-mobile-navigation::after {
  left: 256px;
  border-bottom: 70px solid transparent;
  border-left: 70px solid #fff;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-bar {
    left: -460px;
    width: 460px;
    padding: 40px 40px;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -460px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 460px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 40px;
  padding: 5px;
}
@media (max-width: 959px) {
  .uk-offcanvas-close {
    top: 20px;
    right: 30px;
  }
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-close {
    top: 50px;
    right: 50px;
  }
}
/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 * 1. `clip` is needed for `position: sticky` elements to keep their position
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
  /* 1 */
  overflow-x: clip;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 460px;
  }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -460px;
  }
}
.tm-mobile-shop-navigation {
  background: #e6e981;
}

.tm-second-offcanvas {
  left: 0;
  transition: left 0.3s ease-in;
}
.tm-second-offcanvas.uk-active {
  left: 920px;
  display: block;
  transition: left 0.3s ease-in;
}
.tm-second-offcanvas .tm-shop-navigation {
  background: #e6e981;
}

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none;
}

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 400px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
}

/* Show */
.uk-tooltip.uk-active {
  display: block;
}

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0;
}

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0;
}

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -40px;
}

.uk-grid > * {
  padding-left: 40px;
}

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px;
  }
  .uk-grid > * {
    padding-left: 40px;
  }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -30px;
}

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 30px;
}

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 30px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -40px;
}

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 40px;
}

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 40px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -50px;
}

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 50px;
}

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 50px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -80px;
  }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 80px;
  }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 80px;
  }
}
/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0;
}

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0;
}

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0;
}

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #f0f0f0;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #f0f0f0;
}

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -80px;
}

.uk-grid-divider > * {
  padding-left: 80px;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 40px;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px;
  }
  .uk-grid-divider > * {
    padding-left: 80px;
  }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px;
  }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -60px;
}

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 60px;
}

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 30px;
}

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 60px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -80px;
}

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 80px;
}

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 40px;
}

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px;
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 80px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -100px;
}

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 100px;
}

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 50px;
}

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 100px;
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -50px;
  left: 100px;
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -50px;
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 100px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -160px;
  }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 160px;
  }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 80px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 160px;
  }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -80px;
    left: 160px;
  }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -80px;
  }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 160px;
  }
}
/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
}

.uk-grid-match > * > :not([class*=uk-width]),
.uk-grid-item-match > :not([class*=uk-width]) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto;
}

.tm-products-grid > .uk-grid-margin {
  margin-top: 100px;
}
@media (max-width: 959px) {
  .tm-products-grid > .uk-grid-margin {
    margin-top: 40px;
  }
}

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none;
}

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0;
}

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px;
}

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px;
}

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0;
}

/* Parent icon
 ========================================================================== */
.uk-nav-parent-icon {
  margin-left: auto;
  transition: transform 0.3s ease-out;
}

.uk-nav > li.uk-open > a .uk-nav-parent-icon {
  transform: rotateX(180deg);
}

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.889rem;
}

.uk-nav-header:not(:first-child) {
  margin-top: 20px;
}

/* Divider
 ========================================================================== */
.uk-nav .uk-nav-divider {
  margin: 5px 0;
}

/* Default modifier
 ========================================================================== */
.uk-nav-default {
  font-size: 1.667rem;
  line-height: 1.667;
  font-weight: 700;
}

/*
 * Items
 */
.uk-nav-default > li > a {
  color: #000;
}

/* Hover */
.uk-nav-default > li > a:hover {
  color: #000;
}

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #575757;
}

/*
 * Subtitle
 */
.uk-nav-default .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #575757;
}

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #f0f0f0;
}

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub {
  font-size: 1.667rem;
  line-height: 1.667;
}

.uk-nav-default .uk-nav-sub a {
  color: #e2e2e2;
}

.uk-nav-default .uk-nav-sub a:hover {
  color: #000;
}

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #575757;
}

/* Primary modifier
 ========================================================================== */
.uk-nav-primary {
  font-size: 1.111rem;
  line-height: 1.667;
  font-weight: 500;
}
.uk-nav-primary span {
  border-bottom: 1px solid transparent;
}

/*
 * Items
 */
.uk-nav-primary > li > a {
  color: #000;
}

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #000;
  background: unset;
}
.uk-nav-primary > li > a:hover > span {
  border-bottom: 1px solid #000;
}

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #000;
}

/*
 * Subtitle
 */
.uk-nav-primary .uk-nav-subtitle {
  font-size: 1.111rem;
}

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #575757;
}

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #f0f0f0;
}

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub {
  font-size: 1.111rem;
  line-height: 1.667;
}

.uk-nav-primary .uk-nav-sub a {
  color: #e2e2e2;
}

.uk-nav-primary .uk-nav-sub a:hover {
  color: #000;
}

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #575757;
}

/* Secondary modifier
 ========================================================================== */
.uk-nav-secondary {
  font-size: 18px;
  line-height: 1.667;
}
.uk-nav-secondary > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 0;
}

/*
 * Items
 */
.uk-nav-secondary > li > a {
  color: #000;
  font-weight: 500;
  font-size: 0.889rem;
  padding: 10px 0;
}
.uk-nav-secondary > li > a > span {
  border-bottom: 1px solid transparent;
}

/* Hover */
.uk-nav-secondary > li > a:hover {
  color: #000;
  background: unset;
}
.uk-nav-secondary > li > a:hover > span {
  border-bottom: 1px solid #000;
}

/* Active */
.uk-nav-secondary > li.uk-active > a {
  color: #575757;
  background-color: hsl(0, 0%, 125.8774509804%);
}

/*
 * Subtitle
 */
.uk-nav-secondary .uk-nav-subtitle {
  font-size: 0.889rem;
  color: #e2e2e2;
}

/* Hover */
.uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: #000;
}

/* Active */
.uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #575757;
}

/*
 * Header
 */
.uk-nav-secondary .uk-nav-header {
  color: #575757;
}

/*
 * Divider
 */
.uk-nav-secondary .uk-nav-divider {
  border-top: 1px solid #f0f0f0;
}

/*
 * Sublists
 */
.uk-nav-secondary .uk-nav-sub {
  font-size: 0.889rem;
  line-height: 1.667;
}

.uk-nav-secondary .uk-nav-sub a {
  color: #e2e2e2;
}

.uk-nav-secondary .uk-nav-sub a:hover {
  color: #000;
}

.uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #575757;
}

/* Size modifier
 ========================================================================== */
/*
 * Medium
 */
.uk-nav-medium {
  font-size: 2.8875rem;
  line-height: 1;
}

.uk-nav-large {
  font-size: 3.4rem;
  line-height: 1;
}

.uk-nav-xlarge {
  font-size: 4rem;
  line-height: 1;
}

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-nav-medium {
    font-size: 3.5rem;
  }
  .uk-nav-large {
    font-size: 4rem;
  }
  .uk-nav-xlarge {
    font-size: 6rem;
  }
}
/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-nav-medium {
    font-size: 4rem;
  }
  .uk-nav-large {
    font-size: 6rem;
  }
  .uk-nav-xlarge {
    font-size: 8rem;
  }
}
/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center;
}

/* 2 */
.uk-nav-center li > a {
  justify-content: center;
}

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0;
}

/* Parent icon  */
.uk-nav-center .uk-nav-parent-icon {
  margin-left: 0.25em;
}

/* Style modifier
 ========================================================================== */
/*
 * Divider
 * Naming is in plural to prevent conflicts with divider sub object.
 */
.uk-nav.uk-nav-divider > :not(.uk-nav-header, .uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #f0f0f0;
}

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative;
}
.uk-navbar .uk-navbar-nav.tm-navbar-language {
  gap: 10px;
}
.uk-navbar .uk-navbar-nav.tm-navbar-language li:not(.uk-active) a {
  color: #767676;
}
.uk-navbar .uk-navbar-nav.tm-navbar-language li:not(.uk-active) a:hover {
  color: #a5a5a5;
}
.uk-navbar .uk-navbar-nav.tm-navbar-language li {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #fff;
  position: relative;
}

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 */
.uk-navbar-left,
.uk-navbar-right,
[class*=uk-navbar-center] {
  display: flex;
  gap: 15px;
  /* 1 */
  align-items: center;
}

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto;
}

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative;
}

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990;
}

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0;
}

.uk-navbar-center-left {
  right: calc(100% + 15px);
}

.uk-navbar-center-right {
  left: calc(100% + 15px);
}

[class*=uk-navbar-center-] {
  width: max-content;
  box-sizing: border-box;
}

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  gap: 40px;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap;
}

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 100px;
  /* 4 */
  font-size: 0.889rem;
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 5 */
  text-decoration: none;
}

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  padding: 0 0;
  color: #000;
  text-transform: none;
  font-weight: 500;
}
@media (max-width: 959px) {
  .uk-navbar-nav > li > a {
    min-height: 70px !important;
    height: 70px !important;
  }
}
.uk-navbar-nav > li > a > span {
  border-bottom: 1px solid transparent;
  line-height: 1.1rem;
}
.uk-navbar-nav > li > a.tm-nav-shop > span {
  background: #d6da2d;
  border-radius: 20px;
  padding: 3px 15px;
}

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #000;
}
.uk-navbar-nav > li:hover > a > span,
.uk-navbar-nav > li > a[aria-expanded=true] > span {
  border-bottom: 1px solid #000;
}
.uk-navbar-nav > li:hover > a.tm-nav-shop > span,
.uk-navbar-nav > li > a[aria-expanded=true].tm-nav-shop > span {
  border-bottom-color: transparent;
}

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #000;
}

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #000;
}
.uk-navbar-nav > li.uk-active > a > span {
  border-bottom: 1px solid #000;
}
.uk-navbar-nav > li.uk-active > a.tm-nav-shop > span {
  border-bottom-color: transparent;
}

/* Parent icon modifier
 ========================================================================== */
.uk-navbar-parent-icon {
  margin-left: 4px;
  transition: transform 0.3s ease-out;
}

.uk-navbar-nav > li > a[aria-expanded=true] .uk-navbar-parent-icon {
  transform: rotateX(180deg);
}

/* Item
 ========================================================================== */
.uk-navbar-item {
  padding: 0 0;
  color: #000;
}
@media (max-width: 959px) {
  .uk-navbar-item {
    min-height: 70px !important;
    height: 70px !important;
  }
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0;
}

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  padding: 0 0;
  color: #e2e2e2;
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded=true] {
  color: #fff;
  text-decoration: none;
}

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.889rem;
}

/* Justify modifier
 ========================================================================== */
.uk-navbar-justify .uk-navbar-left,
.uk-navbar-justify .uk-navbar-right,
.uk-navbar-justify .uk-navbar-nav,
.uk-navbar-justify .uk-navbar-nav > li,
.uk-navbar-justify .uk-navbar-item,
.uk-navbar-justify .uk-navbar-toggle {
  flex-grow: 1;
}

/* Style modifiers
 ========================================================================== */
.uk-navbar-transparent {
  position: relative;
}
.uk-navbar-transparent::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 256px;
  background: #fff;
}
@media (max-width: 959px) {
  .uk-navbar-transparent::before {
    width: 156px;
  }
}
.uk-navbar-transparent::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 256px;
  width: 0;
  height: 0;
  border-bottom: 100px solid transparent;
  border-left: 100px solid #fff;
}
@media (max-width: 959px) {
  .uk-navbar-transparent::after {
    left: 156px;
    border-bottom: 70px solid transparent;
    border-left: 70px solid #fff;
  }
}

/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-drop`
 * 1. Set a default width
 * 2. Style
 */
.uk-navbar-dropdown {
  --uk-position-offset: 15px;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  --uk-inverse: dark;
  /* 1 */
  width: 200px;
  /* 2 */
  padding: 25px;
  background: #fff;
  color: #000;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-dropdown > :last-child {
  margin-bottom: 0;
}

.uk-navbar-dropdown :focus-visible {
  outline-color: #575757 !important;
}

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown .uk-drop-grid {
  margin-left: -40px;
}

.uk-navbar-dropdown .uk-drop-grid > * {
  padding-left: 40px;
}

/* Gutter Vertical */
.uk-navbar-dropdown .uk-drop-grid > .uk-grid-margin {
  margin-top: 40px;
}

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-drop-stack) {
  width: 400px;
}

.uk-navbar-dropdown-width-3:not(.uk-drop-stack) {
  width: 600px;
}

.uk-navbar-dropdown-width-4:not(.uk-drop-stack) {
  width: 800px;
}

.uk-navbar-dropdown-width-5:not(.uk-drop-stack) {
  width: 1000px;
}

/*
 * Size modifier
 */
.uk-navbar-dropdown-large {
  --uk-position-shift-offset: 0;
  padding: 40px;
}

/*
 * Dropbar modifier
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-navbar-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 25px 0 25px 0;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  box-shadow: none;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 40px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 50px;
  }
}
.uk-navbar-dropdown-dropbar-large {
  --uk-position-shift-offset: 0;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-navbar-dropdown-nav {
  font-size: 0.889rem;
}

/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #e2e2e2;
}

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #000;
}

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #575757;
}

/*
 * Subtitle
 */
.uk-navbar-dropdown-nav .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #575757;
}

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #f0f0f0;
}

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #e2e2e2;
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #000;
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #575757;
}

/* Dropbar
 ========================================================================== */
/*
 * Adopts `uk-dropnav-dropbar`
 */
@media (max-width: 959px) {
  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    min-height: 70px !important;
    height: 70px !important;
  }
}

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #e2e2e2;
  font-size: 0.889rem;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/* Hover */
.uk-subnav > * > a:hover {
  color: #000;
  text-decoration: none;
}

/* Active */
.uk-subnav > .uk-active > a {
  color: #575757;
}

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px;
}

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center;
}

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent;
}

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #f0f0f0;
}

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #e2e2e2;
}

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: hsl(0, 0%, 125.8774509804%);
  color: #000;
}

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: hsl(0, 0%, 125.8774509804%);
  color: #000;
}

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #34382e;
  color: #fff;
}

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #e2e2e2;
}

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none;
}

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents;
}

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.889rem;
  color: #767676;
  font-weight: 500;
}

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #000;
  text-decoration: none;
}

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #000;
}

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  /* 1 */
  margin: 0 10px 0 calc(10px - 4px);
  /* 2 */
  font-size: 0.889rem;
  color: #e2e2e2;
}

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: 0;
  /* 4 */
  padding: 0;
  list-style: none;
}
.uk-pagination .uk-icon-button {
  background: #000;
  color: #fff;
}
.uk-pagination .uk-disabled .uk-icon-button {
  background: #f0f0f0;
  color: #000;
}
.uk-pagination a {
  text-decoration: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  padding: 5px 10px;
  color: #a5a5a5;
  transition: color 0.1s ease-in-out;
}

/* Hover */
.uk-pagination > * > :hover {
  color: #000;
  text-decoration: none;
}

/* Active */
.uk-pagination > .uk-active > * {
  color: #000;
  font-weight: 500;
}

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #e2e2e2;
}

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px;
}

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.4);
  transition: 0.2s ease-in-out;
  transition-property: background-color, border-color;
}

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
}

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
}

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
}

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px;
}

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px;
}

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-thumbnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -15px;
  gap: 10px;
  flex-wrap: nowrap;
}
.uk-thumbnav > * {
  width: 22%;
  padding-left: 0 !important;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-thumbnav > * {
  /* 1 */
  padding-left: 15px;
}

/* Items
 ========================================================================== */
/*
 * Items
 */
.uk-thumbnav > * > * {
  display: inline-block;
  position: relative;
}
.uk-thumbnav > * > *::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4));
  transition: opacity 0.1s ease-in-out;
}

/* Hover */
.uk-thumbnav > * > :hover::after {
  opacity: 0;
}

/* Active */
.uk-thumbnav > .uk-active > * {
  border: 2px solid black;
}

/* Modifier: 'uk-thumbnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-thumbnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -15px;
}

/* 2 */
.uk-thumbnav-vertical > * {
  padding-left: 0;
  padding-top: 15px;
}

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px;
}
.uk-iconnav.tm-iconnav-mobile {
  gap: 10px;
  color: #000;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */
.uk-iconnav > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  line-height: 0;
  /* 4 */
  color: #000;
  /* 5 */
  text-decoration: none;
  font-size: 0.889rem;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/* Hover */
.uk-iconnav > * > a:hover {
  color: #000;
}

/* Active */
.uk-iconnav > .uk-active > a {
  color: #000;
}

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px;
}

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px;
}

/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/*
 * Focus
 */
.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7);
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-lightbox-button:hover {
  color: #fff;
}

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*=uk-child-width] > * {
  box-sizing: border-box;
  width: 100%;
}

.uk-child-width-1-2 > * {
  width: 50%;
}

.uk-child-width-1-3 > * {
  width: calc(100% / 3);
}

.uk-child-width-1-4 > * {
  width: 25%;
}

.uk-child-width-1-5 > * {
  width: 20%;
}

.uk-child-width-1-6 > * {
  width: calc(100% / 6);
}

.uk-child-width-auto > * {
  width: auto;
}

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*=uk-width]) {
  flex: 1;
  /* 1 */
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%;
  }
  .uk-child-width-1-2\@s > * {
    width: 50%;
  }
  .uk-child-width-1-3\@s > * {
    width: calc(100% / 3);
  }
  .uk-child-width-1-4\@s > * {
    width: 25%;
  }
  .uk-child-width-1-5\@s > * {
    width: 20%;
  }
  .uk-child-width-1-6\@s > * {
    width: calc(100% / 6);
  }
  .uk-child-width-auto\@s > * {
    width: auto;
  }
  .uk-child-width-expand\@s > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-child-width-1-1\@s > :not([class*=uk-width]),
  .uk-child-width-1-2\@s > :not([class*=uk-width]),
  .uk-child-width-1-3\@s > :not([class*=uk-width]),
  .uk-child-width-1-4\@s > :not([class*=uk-width]),
  .uk-child-width-1-5\@s > :not([class*=uk-width]),
  .uk-child-width-1-6\@s > :not([class*=uk-width]),
  .uk-child-width-auto\@s > :not([class*=uk-width]) {
    flex: initial;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%;
  }
  .uk-child-width-1-2\@m > * {
    width: 50%;
  }
  .uk-child-width-1-3\@m > * {
    width: calc(100% / 3);
  }
  .uk-child-width-1-4\@m > * {
    width: 25%;
  }
  .uk-child-width-1-5\@m > * {
    width: 20%;
  }
  .uk-child-width-1-6\@m > * {
    width: calc(100% / 6);
  }
  .uk-child-width-auto\@m > * {
    width: auto;
  }
  .uk-child-width-expand\@m > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-child-width-1-1\@m > :not([class*=uk-width]),
  .uk-child-width-1-2\@m > :not([class*=uk-width]),
  .uk-child-width-1-3\@m > :not([class*=uk-width]),
  .uk-child-width-1-4\@m > :not([class*=uk-width]),
  .uk-child-width-1-5\@m > :not([class*=uk-width]),
  .uk-child-width-1-6\@m > :not([class*=uk-width]),
  .uk-child-width-auto\@m > :not([class*=uk-width]) {
    flex: initial;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%;
  }
  .uk-child-width-1-2\@l > * {
    width: 50%;
  }
  .uk-child-width-1-3\@l > * {
    width: calc(100% / 3);
  }
  .uk-child-width-1-4\@l > * {
    width: 25%;
  }
  .uk-child-width-1-5\@l > * {
    width: 20%;
  }
  .uk-child-width-1-6\@l > * {
    width: calc(100% / 6);
  }
  .uk-child-width-auto\@l > * {
    width: auto;
  }
  .uk-child-width-expand\@l > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-child-width-1-1\@l > :not([class*=uk-width]),
  .uk-child-width-1-2\@l > :not([class*=uk-width]),
  .uk-child-width-1-3\@l > :not([class*=uk-width]),
  .uk-child-width-1-4\@l > :not([class*=uk-width]),
  .uk-child-width-1-5\@l > :not([class*=uk-width]),
  .uk-child-width-1-6\@l > :not([class*=uk-width]),
  .uk-child-width-auto\@l > :not([class*=uk-width]) {
    flex: initial;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%;
  }
  .uk-child-width-1-2\@xl > * {
    width: 50%;
  }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% / 3);
  }
  .uk-child-width-1-4\@xl > * {
    width: 25%;
  }
  .uk-child-width-1-5\@xl > * {
    width: 20%;
  }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% / 6);
  }
  .uk-child-width-auto\@xl > * {
    width: auto;
  }
  .uk-child-width-expand\@xl > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-child-width-1-1\@xl > :not([class*=uk-width]),
  .uk-child-width-1-2\@xl > :not([class*=uk-width]),
  .uk-child-width-1-3\@xl > :not([class*=uk-width]),
  .uk-child-width-1-4\@xl > :not([class*=uk-width]),
  .uk-child-width-1-5\@xl > :not([class*=uk-width]),
  .uk-child-width-1-6\@xl > :not([class*=uk-width]),
  .uk-child-width-auto\@xl > :not([class*=uk-width]) {
    flex: initial;
  }
}
/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*=uk-width] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%;
}

/* Halves */
.uk-width-1-2 {
  width: 50%;
}

/* Thirds */
.uk-width-1-3 {
  width: calc(100% / 3);
}

.uk-width-2-3 {
  width: calc(200% / 3);
}

/* Quarters */
.uk-width-1-4 {
  width: 25%;
}

.uk-width-3-4 {
  width: 75%;
}

/* Fifths */
.uk-width-1-5 {
  width: 20%;
}

.uk-width-2-5 {
  width: 40%;
}

.uk-width-3-5 {
  width: 60%;
}

.uk-width-4-5 {
  width: 80%;
}

/* Sixths */
.uk-width-1-6 {
  width: calc(100% / 6);
}

.uk-width-5-6 {
  width: calc(500% / 6);
}

/* Pixel */
.uk-width-small {
  width: 150px;
}

.uk-width-medium {
  width: 300px;
}

.uk-width-large {
  width: 420px;
}

.uk-width-xlarge {
  width: 600px;
}

.uk-width-2xlarge {
  width: 750px;
}

/* Auto */
.uk-width-auto {
  width: auto;
}

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% / 3);
  }
  .uk-width-2-3\@s {
    width: calc(200% / 3);
  }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%;
  }
  .uk-width-3-4\@s {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%;
  }
  .uk-width-2-5\@s {
    width: 40%;
  }
  .uk-width-3-5\@s {
    width: 60%;
  }
  .uk-width-4-5\@s {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% / 6);
  }
  .uk-width-5-6\@s {
    width: calc(500% / 6);
  }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px;
  }
  .uk-width-medium\@s {
    width: 300px;
  }
  .uk-width-large\@s {
    width: 420px;
  }
  .uk-width-xlarge\@s {
    width: 600px;
  }
  .uk-width-2xlarge\@s {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@s {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-width-1-1\@s,
  .uk-width-1-2\@s,
  .uk-width-1-3\@s,
  .uk-width-2-3\@s,
  .uk-width-1-4\@s,
  .uk-width-3-4\@s,
  .uk-width-1-5\@s,
  .uk-width-2-5\@s,
  .uk-width-3-5\@s,
  .uk-width-4-5\@s,
  .uk-width-1-6\@s,
  .uk-width-5-6\@s,
  .uk-width-small\@s,
  .uk-width-medium\@s,
  .uk-width-large\@s,
  .uk-width-xlarge\@s,
  .uk-width-2xlarge\@s,
  .uk-width-auto\@s {
    flex: initial;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% / 3);
  }
  .uk-width-2-3\@m {
    width: calc(200% / 3);
  }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%;
  }
  .uk-width-3-4\@m {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%;
  }
  .uk-width-2-5\@m {
    width: 40%;
  }
  .uk-width-3-5\@m {
    width: 60%;
  }
  .uk-width-4-5\@m {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% / 6);
  }
  .uk-width-5-6\@m {
    width: calc(500% / 6);
  }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px;
  }
  .uk-width-medium\@m {
    width: 300px;
  }
  .uk-width-large\@m {
    width: 420px;
  }
  .uk-width-xlarge\@m {
    width: 600px;
  }
  .uk-width-2xlarge\@m {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@m {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-width-1-1\@m,
  .uk-width-1-2\@m,
  .uk-width-1-3\@m,
  .uk-width-2-3\@m,
  .uk-width-1-4\@m,
  .uk-width-3-4\@m,
  .uk-width-1-5\@m,
  .uk-width-2-5\@m,
  .uk-width-3-5\@m,
  .uk-width-4-5\@m,
  .uk-width-1-6\@m,
  .uk-width-5-6\@m,
  .uk-width-small\@m,
  .uk-width-medium\@m,
  .uk-width-large\@m,
  .uk-width-xlarge\@m,
  .uk-width-2xlarge\@m,
  .uk-width-auto\@m {
    flex: initial;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% / 3);
  }
  .uk-width-2-3\@l {
    width: calc(200% / 3);
  }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%;
  }
  .uk-width-3-4\@l {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%;
  }
  .uk-width-2-5\@l {
    width: 40%;
  }
  .uk-width-3-5\@l {
    width: 60%;
  }
  .uk-width-4-5\@l {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% / 6);
  }
  .uk-width-5-6\@l {
    width: calc(500% / 6);
  }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px;
  }
  .uk-width-medium\@l {
    width: 300px;
  }
  .uk-width-large\@l {
    width: 420px;
  }
  .uk-width-xlarge\@l {
    width: 600px;
  }
  .uk-width-2xlarge\@l {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@l {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-width-1-1\@l,
  .uk-width-1-2\@l,
  .uk-width-1-3\@l,
  .uk-width-2-3\@l,
  .uk-width-1-4\@l,
  .uk-width-3-4\@l,
  .uk-width-1-5\@l,
  .uk-width-2-5\@l,
  .uk-width-3-5\@l,
  .uk-width-4-5\@l,
  .uk-width-1-6\@l,
  .uk-width-5-6\@l,
  .uk-width-small\@l,
  .uk-width-medium\@l,
  .uk-width-large\@l,
  .uk-width-xlarge\@l,
  .uk-width-2xlarge\@l,
  .uk-width-auto\@l {
    flex: initial;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% / 3);
  }
  .uk-width-2-3\@xl {
    width: calc(200% / 3);
  }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%;
  }
  .uk-width-3-4\@xl {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%;
  }
  .uk-width-2-5\@xl {
    width: 40%;
  }
  .uk-width-3-5\@xl {
    width: 60%;
  }
  .uk-width-4-5\@xl {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% / 6);
  }
  .uk-width-5-6\@xl {
    width: calc(500% / 6);
  }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px;
  }
  .uk-width-medium\@xl {
    width: 300px;
  }
  .uk-width-large\@xl {
    width: 420px;
  }
  .uk-width-xlarge\@xl {
    width: 600px;
  }
  .uk-width-2xlarge\@xl {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
  /* Reset expand */
  .uk-width-1-1\@xl,
  .uk-width-1-2\@xl,
  .uk-width-1-3\@xl,
  .uk-width-2-3\@xl,
  .uk-width-1-4\@xl,
  .uk-width-3-4\@xl,
  .uk-width-1-5\@xl,
  .uk-width-2-5\@xl,
  .uk-width-3-5\@xl,
  .uk-width-4-5\@xl,
  .uk-width-1-6\@xl,
  .uk-width-5-6\@xl,
  .uk-width-small\@xl,
  .uk-width-medium\@xl,
  .uk-width-large\@xl,
  .uk-width-xlarge\@xl,
  .uk-width-2xlarge\@xl,
  .uk-width-auto\@xl {
    flex: initial;
  }
}
/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content;
}

.uk-width-min-content {
  width: min-content;
}

@media (min-width: 960px) {
  .tm-card-employee {
    width: 420px;
  }
}

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*=uk-height] {
  box-sizing: border-box;
}

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%;
}

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh;
}

.uk-height-viewport-2 {
  min-height: 200vh;
}

.uk-height-viewport-3 {
  min-height: 300vh;
}

.uk-height-viewport-4 {
  min-height: 400vh;
}

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px;
}

.uk-height-medium {
  height: 300px;
}

.uk-height-large {
  height: 600px;
}

.uk-height-max-small {
  max-height: 150px;
}

.uk-height-max-medium {
  max-height: 300px;
}

.uk-height-max-large {
  max-height: 600px;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.667rem;
  line-height: 1.4;
  color: #000;
  font-weight: 500;
}
@media (max-width: 1199px) {
  .uk-text-lead {
    font-size: 1.111rem;
  }
}

.uk-text-meta {
  font-size: 0.7778rem;
  line-height: 1.7143;
  color: #000;
}
.uk-text-meta > a {
  color: #000;
}
.uk-text-meta > a:hover {
  color: #000;
  text-decoration: none;
}

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.889rem;
  line-height: 1.4;
}

.uk-text-large {
  font-size: 1.222rem;
  line-height: 1.638;
}

.uk-text-default {
  font-size: 18px;
  line-height: 1.667;
}

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300;
}

.uk-text-normal {
  font-weight: 400;
}

.uk-text-bold {
  font-weight: 700;
}

.uk-text-lighter {
  font-weight: lighter;
}

.uk-text-bolder {
  font-weight: bolder;
}

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic;
}

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #767676 !important;
}

.uk-text-emphasis {
  color: #767676 !important;
}

.uk-text-primary {
  color: #34382e !important;
}

.uk-text-secondary {
  color: #d6da2d !important;
}

.uk-text-success {
  color: #32cd32 !important;
}

.uk-text-warning {
  color: #ffa500 !important;
}

.uk-text-danger {
  color: #f00000 !important;
}

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in all browsers.
 * 2. Default color is set to transparent.
 * 3. Container fits the text
 * 4. Style
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  color: transparent !important;
  /* 3 */
  display: inline-block;
  /* 4 */
  background-color: #34382e;
  background-image: linear-gradient(90deg, #34382e 0%, rgb(46, 56, 46.6666666667) 100%);
}

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important;
  }
  .uk-text-right\@s {
    text-align: right !important;
  }
  .uk-text-center\@s {
    text-align: center !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important;
  }
  .uk-text-right\@m {
    text-align: right !important;
  }
  .uk-text-center\@m {
    text-align: center !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important;
  }
  .uk-text-right\@l {
    text-align: right !important;
  }
  .uk-text-center\@l {
    text-align: center !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important;
  }
  .uk-text-right\@xl {
    text-align: right !important;
  }
  .uk-text-center\@xl {
    text-align: center !important;
  }
}
/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

.uk-text-baseline {
  vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0;
}

/*
 * Wrap long words onto the next line and break them if they are too long to fit.
 * 1. Make it work with table cells in all browsers.
 * Note: Not using `hyphens: auto` because it hyphenates text even if not needed.
 */
.uk-text-break {
  overflow-wrap: break-word;
}

/* 1 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-word;
}

.uk-text-medium {
  font-weight: 500 !important;
}

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover]:where(canvas, iframe, svg),
[data-uk-cover]:where(canvas, iframe, svg) {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
}

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none;
}

[uk-cover]:where(img, video),
[data-uk-cover]:where(img, video) {
  /* 3 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
}

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative;
}

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff;
}

.uk-background-muted {
  background-color: #f0f0f0;
}

.uk-background-primary {
  background-color: #34382e;
}

.uk-background-secondary {
  background-color: #d6da2d;
}

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.uk-background-cover {
  background-size: cover;
}

.uk-background-contain {
  background-size: contain;
}

.uk-background-width-1-1 {
  background-size: 100%;
}

.uk-background-height-1-1 {
  background-size: auto 100%;
}

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0;
}

.uk-background-top-center {
  background-position: 50% 0;
}

.uk-background-top-right {
  background-position: 100% 0;
}

.uk-background-center-left {
  background-position: 0 50%;
}

.uk-background-center-center {
  background-position: 50% 50%;
}

.uk-background-center-right {
  background-position: 100% 50%;
}

.uk-background-bottom-left {
  background-position: 0 100%;
}

.uk-background-bottom-center {
  background-position: 50% 100%;
}

.uk-background-bottom-right {
  background-position: 100% 100%;
}

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat;
}

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden;
}

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll;
  }
}
/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important;
  }
}
/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply;
}

.uk-background-blend-screen {
  background-blend-mode: screen;
}

.uk-background-blend-overlay {
  background-blend-mode: overlay;
}

.uk-background-blend-darken {
  background-blend-mode: darken;
}

.uk-background-blend-lighten {
  background-blend-mode: lighten;
}

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.uk-background-blend-color-burn {
  background-blend-mode: color-burn;
}

.uk-background-blend-hard-light {
  background-blend-mode: hard-light;
}

.uk-background-blend-soft-light {
  background-blend-mode: soft-light;
}

.uk-background-blend-difference {
  background-blend-mode: difference;
}

.uk-background-blend-exclusion {
  background-blend-mode: exclusion;
}

.uk-background-blend-hue {
  background-blend-mode: hue;
}

.uk-background-blend-saturation {
  background-blend-mode: saturation;
}

.uk-background-blend-color {
  background-blend-mode: color;
}

.uk-background-blend-luminosity {
  background-blend-mode: luminosity;
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
}

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0;
}

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #f0f0f0;
  overflow: auto;
  resize: both;
}

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell;
}

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*=uk-float-] {
  max-width: 100%;
}

/* Overflow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}

/*
 * Enable scrollbars if content is clipped
 */
.uk-overflow-auto {
  overflow: auto;
}

.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}

/* Box Sizing
 ========================================================================== */
.uk-box-sizing-content {
  box-sizing: content-box;
}

.uk-box-sizing-border {
  box-sizing: border-box;
}

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both;
}

.uk-resize-horizontal {
  resize: horizontal;
}

.uk-resize-vertical {
  resize: vertical;
}

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
}

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*=uk-inline] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
  /* 6 */
  overflow: hidden;
}

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none;
}

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto;
}

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none;
}

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%;
}

/* Object
 ========================================================================== */
.uk-object-cover {
  object-fit: cover;
}

.uk-object-contain {
  object-fit: contain;
}

.uk-object-fill {
  object-fit: fill;
}

.uk-object-none {
  object-fit: none;
}

.uk-object-scale-down {
  object-fit: scale-down;
}

/*
 * Position
 */
.uk-object-top-left {
  object-position: 0 0;
}

.uk-object-top-center {
  object-position: 50% 0;
}

.uk-object-top-right {
  object-position: 100% 0;
}

.uk-object-center-left {
  object-position: 0 50%;
}

.uk-object-center-center {
  object-position: 50% 50%;
}

.uk-object-center-right {
  object-position: 100% 50%;
}

.uk-object-bottom-left {
  object-position: 0 100%;
}

.uk-object-bottom-center {
  object-position: 50% 100%;
}

.uk-object-bottom-right {
  object-position: 100% 100%;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-pill {
  border-radius: 500px;
}

.uk-border-rounded {
  border-radius: 5px;
}

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*=uk-border-] {
  -webkit-transform: translateZ(0);
}

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/*
 * Hover
 */
[class*=uk-box-shadow-hover] {
  transition: box-shadow 0.1s ease-in-out;
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle;
  }
  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter;
  }
}
/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 5.001em;
  line-height: 1;
  margin-bottom: -2px;
}

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
  .uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%;
  }
}
/* Logo
 ========================================================================== */
/*
 * 1. Style
 * 2. Required for `a`
 * 3. Behave like image but can be overridden through flex utility classes
 */
.uk-logo {
  /* 1 */
  font-size: 1.222rem;
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #575757;
  /* 2 */
  text-decoration: none;
}

/* 3 */
:where(.uk-logo) {
  display: inline-block;
  vertical-align: middle;
}

/* Hover */
.uk-logo:hover {
  color: #575757;
  /* 1 */
  text-decoration: none;
}

.uk-logo :where(img, svg, video) {
  display: block;
}

.uk-logo-inverse {
  display: none;
}

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none;
}

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move;
}

/* 2 */
.uk-drag iframe {
  pointer-events: none;
}

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply;
}

.uk-blend-screen {
  mix-blend-mode: screen;
}

.uk-blend-overlay {
  mix-blend-mode: overlay;
}

.uk-blend-darken {
  mix-blend-mode: darken;
}

.uk-blend-lighten {
  mix-blend-mode: lighten;
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn;
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light;
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light;
}

.uk-blend-difference {
  mix-blend-mode: difference;
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion;
}

.uk-blend-hue {
  mix-blend-mode: hue;
}

.uk-blend-saturation {
  mix-blend-mode: saturation;
}

.uk-blend-color {
  mix-blend-mode: color;
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity;
}

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%);
}

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0;
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0;
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0;
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%;
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }
  .uk-flex-center\@s {
    justify-content: center;
  }
  .uk-flex-right\@s {
    justify-content: flex-end;
  }
  .uk-flex-between\@s {
    justify-content: space-between;
  }
  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }
  .uk-flex-center\@m {
    justify-content: center;
  }
  .uk-flex-right\@m {
    justify-content: flex-end;
  }
  .uk-flex-between\@m {
    justify-content: space-between;
  }
  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }
  .uk-flex-center\@l {
    justify-content: center;
  }
  .uk-flex-right\@l {
    justify-content: flex-end;
  }
  .uk-flex-between\@l {
    justify-content: space-between;
  }
  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }
  .uk-flex-center\@xl {
    justify-content: center;
  }
  .uk-flex-right\@xl {
    justify-content: flex-end;
  }
  .uk-flex-between\@xl {
    justify-content: space-between;
  }
  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-stretch\@s {
    align-items: stretch;
  }
  .uk-flex-top\@s {
    align-items: flex-start;
  }
  .uk-flex-middle\@s {
    align-items: center;
  }
  .uk-flex-bottom\@s {
    align-items: flex-end;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-stretch\@m {
    align-items: stretch;
  }
  .uk-flex-top\@m {
    align-items: flex-start;
  }
  .uk-flex-middle\@m {
    align-items: center;
  }
  .uk-flex-bottom\@m {
    align-items: flex-end;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-stretch\@l {
    align-items: stretch;
  }
  .uk-flex-top\@l {
    align-items: flex-start;
  }
  .uk-flex-middle\@l {
    align-items: center;
  }
  .uk-flex-bottom\@l {
    align-items: flex-end;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-stretch\@xl {
    align-items: stretch;
  }
  .uk-flex-top\@xl {
    align-items: flex-start;
  }
  .uk-flex-middle\@xl {
    align-items: center;
  }
  .uk-flex-bottom\@xl {
    align-items: flex-end;
  }
}
/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-row\@s {
    flex-direction: row;
  }
  .uk-flex-column\@s {
    flex-direction: column;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-row\@m {
    flex-direction: row;
  }
  .uk-flex-column\@m {
    flex-direction: column;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-row\@l {
    flex-direction: row;
  }
  .uk-flex-column\@l {
    flex-direction: column;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-row\@xl {
    flex-direction: row;
  }
  .uk-flex-column\@xl {
    flex-direction: column;
  }
}
/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }
  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1;
  }
  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1;
  }
  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1;
  }
  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
.uk-flex-initial {
  flex: initial;
}

/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-initial\@s {
    flex: initial;
  }
  .uk-flex-none\@s {
    flex: none;
  }
  .uk-flex-1\@s {
    flex: 1;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-initial\@m {
    flex: initial;
  }
  .uk-flex-none\@m {
    flex: none;
  }
  .uk-flex-1\@m {
    flex: 1;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-initial\@l {
    flex: initial;
  }
  .uk-flex-none\@l {
    flex: none;
  }
  .uk-flex-1\@l {
    flex: 1;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-initial\@xl {
    flex: initial;
  }
  .uk-flex-none\@xl {
    flex: none;
  }
  .uk-flex-1\@xl {
    flex: 1;
  }
}
/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 50px;
  }
  * + .uk-margin-large {
    margin-top: 50px !important;
  }
  .uk-margin-large-top {
    margin-top: 50px !important;
  }
  .uk-margin-large-bottom {
    margin-bottom: 50px !important;
  }
  .uk-margin-large-left {
    margin-left: 50px !important;
  }
  .uk-margin-large-right {
    margin-right: 50px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 50px;
}

* + .uk-margin-xlarge {
  margin-top: 50px !important;
}

.uk-margin-xlarge-top {
  margin-top: 50px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 50px !important;
}

.uk-margin-xlarge-left {
  margin-left: 50px !important;
}

.uk-margin-xlarge-right {
  margin-right: 50px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 80px;
  }
  * + .uk-margin-xlarge {
    margin-top: 80px !important;
  }
  .uk-margin-xlarge-top {
    margin-top: 80px !important;
  }
  .uk-margin-xlarge-bottom {
    margin-bottom: 80px !important;
  }
  .uk-margin-xlarge-left {
    margin-left: 80px !important;
  }
  .uk-margin-xlarge-right {
    margin-right: 80px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 50px;
  }
}
/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 30px;
}

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 20px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 80px;
  }
}
/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}

.uk-padding-remove-top {
  padding-top: 0 !important;
}

.uk-padding-remove-bottom {
  padding-bottom: 0 !important;
}

.uk-padding-remove-left {
  padding-left: 0 !important;
}

.uk-padding-remove-right {
  padding-right: 0 !important;
}

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px;
}

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box;
}

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right],
.uk-position-center-vertical {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y));
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-vertical {
  left: 0;
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center,
.uk-position-center-horizontal {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

.uk-position-center-horizontal {
  top: 0;
  bottom: 0;
}

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 20px;
  --uk-position-margin-offset: 20px;
}

.uk-position-medium {
  margin: 40px;
  --uk-position-margin-offset: 40px;
}

.uk-position-large {
  margin: 40px;
  --uk-position-margin-offset: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 80px;
    --uk-position-margin-offset: 80px;
  }
}
/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-sticky {
  position: sticky !important;
}

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1;
}

.uk-position-z-index-zero {
  z-index: 0;
}

.uk-position-z-index-negative {
  z-index: -1;
}

.uk-position-z-index-high {
  z-index: 990;
}

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*=uk-transition-scale]),
:where([class*=uk-transition-slide]) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0;
}

.uk-transition-fade,
[class*=uk-transition-scale],
[class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0;
}

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1;
}

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*=uk-transition-scale] {
  -webkit-backface-visibility: hidden;
}

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
}

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
}

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1;
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1;
}

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%;
}

.uk-transition-slide-bottom {
  --uk-translate-y: 100%;
}

.uk-transition-slide-left {
  --uk-translate-x: -100%;
}

.uk-transition-slide-right {
  --uk-translate-x: 100%;
}

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px);
}

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px;
}

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px);
}

.uk-transition-slide-right-small {
  --uk-translate-x: 10px;
}

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px);
}

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px;
}

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px);
}

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px;
}

/* Show */
.uk-transition-toggle:hover [class*=uk-transition-slide],
.uk-transition-toggle:focus [class*=uk-transition-slide],
.uk-transition-toggle [class*=uk-transition-slide]:focus-within,
.uk-transition-active.uk-active [class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1;
}

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1;
}

/* Duration modifier
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s;
}

/* Disable modifier
 ========================================================================== */
.uk-transition-disable,
.uk-transition-disable * {
  transition: none !important;
}

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Mind that `display: none`, `visibility: hidden` and `opacity: 0`
 * remove the element from the accessibility tree and that
 * `display: none` and `visibility: hidden` are not focusable.
 *
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Remove space when hidden.
 * 1. Remove from document flow.
 * 2. Hide element and shrink its dimension. Current browsers and screen readers
 *    keep the element in the accessibility tree even with zero dimensions.
 *    Using `tabindex="-1"` will show the element on touch devices.
 *    Note: `clip-path` doesn't work with `tabindex` on touch devices.
 */
.uk-hidden-visually:not(:focus):not(:active):not(:focus-within),
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  /* 1 */
  position: absolute !important;
  /* 2 */
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/*
 * Keep space when hidden.
 * Hide element without shrinking its dimension.
 * Note: `clip-path` doesn't work with hover for elements outside of the toggle box.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important;
}

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important;
  }
}
/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*=uk-card-media]), .uk-overlay-primary {
  color: #fff;
}
.uk-light .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-accordion-title::before, .uk-overlay-primary .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik00ODAtMzMzIDI0MC01NzNsNTEtNTEgMTg5IDE4OSAxODktMTg5IDUxIDUxLTI0MCAyNDBaIi8+PC9zdmc+");
}
.uk-light .uk-open > .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-open > .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-open > .uk-accordion-title::before, .uk-overlay-primary .uk-open > .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Im0yOTEtMzM2LTUxLTUxIDI0MC0yNDAgMjQwIDI0MC01MSA1MS0xODktMTg5LTE4OSAxODlaIi8+PC9zdmc+");
}
.uk-light .uk-article-meta, .uk-section-primary:not(.uk-preserve-color) .uk-article-meta, .uk-card-primary.uk-card-body .uk-article-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-article-meta, .uk-overlay-primary .uk-article-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-badge, .uk-section-primary:not(.uk-preserve-color) .uk-badge, .uk-card-primary.uk-card-body .uk-badge, .uk-card-primary > :not([class*=uk-card-media]) .uk-badge, .uk-overlay-primary .uk-badge {
  background-color: #fff;
  color: #000 !important;
}
.uk-light a, .uk-section-primary:not(.uk-preserve-color) a, .uk-card-primary.uk-card-body a, .uk-card-primary > :not([class*=uk-card-media]) a, .uk-overlay-primary a,
.uk-light .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link,
.uk-card-primary.uk-card-body .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link,
.uk-overlay-primary .uk-link {
  color: #fff;
}
.uk-light a:hover, .uk-section-primary:not(.uk-preserve-color) a:hover, .uk-card-primary.uk-card-body a:hover, .uk-card-primary > :not([class*=uk-card-media]) a:hover, .uk-overlay-primary a:hover,
.uk-light .uk-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-card-primary.uk-card-body .uk-link:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link:hover,
.uk-overlay-primary .uk-link:hover,
.uk-light .uk-link-toggle:hover .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-overlay-primary .uk-link-toggle:hover .uk-link {
  color: #fff;
}
.uk-light :not(pre) > code, .uk-section-primary:not(.uk-preserve-color) :not(pre) > code, .uk-card-primary.uk-card-body :not(pre) > code, .uk-card-primary > :not([class*=uk-card-media]) :not(pre) > code, .uk-overlay-primary :not(pre) > code,
.uk-light :not(pre) > kbd,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-card-primary.uk-card-body :not(pre) > kbd,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > kbd,
.uk-overlay-primary :not(pre) > kbd,
.uk-light :not(pre) > samp,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > samp,
.uk-card-primary.uk-card-body :not(pre) > samp,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > samp,
.uk-overlay-primary :not(pre) > samp {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light em, .uk-section-primary:not(.uk-preserve-color) em, .uk-card-primary.uk-card-body em, .uk-card-primary > :not([class*=uk-card-media]) em, .uk-overlay-primary em {
  color: #fff;
}
.uk-light h1, .uk-section-primary:not(.uk-preserve-color) h1, .uk-card-primary.uk-card-body h1, .uk-card-primary > :not([class*=uk-card-media]) h1, .uk-overlay-primary h1, .uk-light .uk-h1, .uk-section-primary:not(.uk-preserve-color) .uk-h1, .uk-card-primary.uk-card-body .uk-h1, .uk-card-primary > :not([class*=uk-card-media]) .uk-h1, .uk-overlay-primary .uk-h1,
.uk-light h2,
.uk-section-primary:not(.uk-preserve-color) h2,
.uk-card-primary.uk-card-body h2,
.uk-card-primary > :not([class*=uk-card-media]) h2,
.uk-overlay-primary h2, .uk-light .uk-h2, .uk-section-primary:not(.uk-preserve-color) .uk-h2, .uk-card-primary.uk-card-body .uk-h2, .uk-card-primary > :not([class*=uk-card-media]) .uk-h2, .uk-overlay-primary .uk-h2,
.uk-light h3,
.uk-section-primary:not(.uk-preserve-color) h3,
.uk-card-primary.uk-card-body h3,
.uk-card-primary > :not([class*=uk-card-media]) h3,
.uk-overlay-primary h3, .uk-light .uk-h3, .uk-section-primary:not(.uk-preserve-color) .uk-h3, .uk-card-primary.uk-card-body .uk-h3, .uk-card-primary > :not([class*=uk-card-media]) .uk-h3, .uk-overlay-primary .uk-h3,
.uk-light h4,
.uk-section-primary:not(.uk-preserve-color) h4,
.uk-card-primary.uk-card-body h4,
.uk-card-primary > :not([class*=uk-card-media]) h4,
.uk-overlay-primary h4, .uk-light .uk-h4, .uk-section-primary:not(.uk-preserve-color) .uk-h4, .uk-card-primary.uk-card-body .uk-h4, .uk-card-primary > :not([class*=uk-card-media]) .uk-h4, .uk-overlay-primary .uk-h4,
.uk-light h5,
.uk-section-primary:not(.uk-preserve-color) h5,
.uk-card-primary.uk-card-body h5,
.uk-card-primary > :not([class*=uk-card-media]) h5,
.uk-overlay-primary h5, .uk-light .uk-h5, .uk-section-primary:not(.uk-preserve-color) .uk-h5, .uk-card-primary.uk-card-body .uk-h5, .uk-card-primary > :not([class*=uk-card-media]) .uk-h5, .uk-overlay-primary .uk-h5,
.uk-light h6,
.uk-section-primary:not(.uk-preserve-color) h6,
.uk-card-primary.uk-card-body h6,
.uk-card-primary > :not([class*=uk-card-media]) h6,
.uk-overlay-primary h6, .uk-light .uk-h6, .uk-section-primary:not(.uk-preserve-color) .uk-h6, .uk-card-primary.uk-card-body .uk-h6, .uk-card-primary > :not([class*=uk-card-media]) .uk-h6, .uk-overlay-primary .uk-h6,
.uk-light .uk-heading-small,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-card-primary.uk-card-body .uk-heading-small,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-small,
.uk-overlay-primary .uk-heading-small,
.uk-light .uk-heading-medium,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-card-primary.uk-card-body .uk-heading-medium,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-medium,
.uk-overlay-primary .uk-heading-medium,
.uk-light .uk-heading-large,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-card-primary.uk-card-body .uk-heading-large,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-large,
.uk-overlay-primary .uk-heading-large,
.uk-light .uk-heading-xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-card-primary.uk-card-body .uk-heading-xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-overlay-primary .uk-heading-xlarge,
.uk-light .uk-heading-2xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-card-primary.uk-card-body .uk-heading-2xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-overlay-primary .uk-heading-2xlarge,
.uk-light .uk-heading-3xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-3xlarge,
.uk-card-primary.uk-card-body .uk-heading-3xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-3xlarge,
.uk-overlay-primary .uk-heading-3xlarge {
  color: #fff;
}
.uk-light blockquote, .uk-section-primary:not(.uk-preserve-color) blockquote, .uk-card-primary.uk-card-body blockquote, .uk-card-primary > :not([class*=uk-card-media]) blockquote, .uk-overlay-primary blockquote {
  color: #fff;
}
.uk-light blockquote footer, .uk-section-primary:not(.uk-preserve-color) blockquote footer, .uk-card-primary.uk-card-body blockquote footer, .uk-card-primary > :not([class*=uk-card-media]) blockquote footer, .uk-overlay-primary blockquote footer {
  color: #fff;
}
.uk-light hr, .uk-section-primary:not(.uk-preserve-color) hr, .uk-card-primary.uk-card-body hr, .uk-card-primary > :not([class*=uk-card-media]) hr, .uk-overlay-primary hr, .uk-light .uk-hr, .uk-section-primary:not(.uk-preserve-color) .uk-hr, .uk-card-primary.uk-card-body .uk-hr, .uk-card-primary > :not([class*=uk-card-media]) .uk-hr, .uk-overlay-primary .uk-hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light :focus-visible, .uk-section-primary:not(.uk-preserve-color) :focus-visible, .uk-card-primary.uk-card-body :focus-visible, .uk-card-primary > :not([class*=uk-card-media]) :focus-visible, .uk-overlay-primary :focus-visible {
  outline-color: #fff;
}
.uk-light .uk-breadcrumb > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-card-primary.uk-card-body .uk-breadcrumb > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > *, .uk-overlay-primary .uk-breadcrumb > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-breadcrumb > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > :hover, .uk-overlay-primary .uk-breadcrumb > * > :hover {
  color: #fff;
}
.uk-light .uk-breadcrumb > :last-child > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-card-primary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :last-child > *, .uk-overlay-primary .uk-breadcrumb > :last-child > * {
  color: #fff;
}
.uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-button-default, .uk-section-primary:not(.uk-preserve-color) .uk-button-default, .uk-card-primary.uk-card-body .uk-button-default, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default, .uk-overlay-primary .uk-button-default {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-button-default:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-card-primary.uk-card-body .uk-button-default:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:hover, .uk-overlay-primary .uk-button-default:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-button-default:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-card-primary.uk-card-body .uk-button-default:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:active, .uk-overlay-primary .uk-button-default:active,
.uk-light .uk-button-default.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-card-primary.uk-card-body .uk-button-default.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-overlay-primary .uk-button-default.uk-active {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-button-primary, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary, .uk-card-primary.uk-card-body .uk-button-primary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary, .uk-overlay-primary .uk-button-primary {
  background-color: #fff;
  color: #000;
}
.uk-light .uk-button-primary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-card-primary.uk-card-body .uk-button-primary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:hover, .uk-overlay-primary .uk-button-primary:hover {
  background-color: rgb(242.25, 242.25, 242.25);
  color: #000;
}
.uk-light .uk-button-primary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-card-primary.uk-card-body .uk-button-primary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:active, .uk-overlay-primary .uk-button-primary:active,
.uk-light .uk-button-primary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-card-primary.uk-card-body .uk-button-primary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-overlay-primary .uk-button-primary.uk-active {
  background-color: rgb(229.5, 229.5, 229.5);
  color: #000;
}
.uk-light .uk-button-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-card-primary.uk-card-body .uk-button-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary, .uk-overlay-primary .uk-button-secondary {
  background-color: #fff;
  color: #000;
}
.uk-light .uk-button-secondary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-card-primary.uk-card-body .uk-button-secondary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:hover, .uk-overlay-primary .uk-button-secondary:hover {
  background-color: rgb(242.25, 242.25, 242.25);
  color: #000;
}
.uk-light .uk-button-secondary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-card-primary.uk-card-body .uk-button-secondary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:active, .uk-overlay-primary .uk-button-secondary:active,
.uk-light .uk-button-secondary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-overlay-primary .uk-button-secondary.uk-active {
  background-color: rgb(229.5, 229.5, 229.5);
  color: #000;
}
.uk-light .uk-button-text, .uk-section-primary:not(.uk-preserve-color) .uk-button-text, .uk-card-primary.uk-card-body .uk-button-text, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text, .uk-overlay-primary .uk-button-text {
  color: #fff;
}
.uk-light .uk-button-text::before, .uk-section-primary:not(.uk-preserve-color) .uk-button-text::before, .uk-card-primary.uk-card-body .uk-button-text::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text::before, .uk-overlay-primary .uk-button-text::before {
  border-bottom-color: #fff;
}
.uk-light .uk-button-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-card-primary.uk-card-body .uk-button-text:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:hover, .uk-overlay-primary .uk-button-text:hover {
  color: #fff;
}
.uk-light .uk-button-text:disabled, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-card-primary.uk-card-body .uk-button-text:disabled, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:disabled, .uk-overlay-primary .uk-button-text:disabled {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-button-link, .uk-section-primary:not(.uk-preserve-color) .uk-button-link, .uk-card-primary.uk-card-body .uk-button-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link, .uk-overlay-primary .uk-button-link {
  color: #fff;
}
.uk-light .uk-button-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-card-primary.uk-card-body .uk-button-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link:hover, .uk-overlay-primary .uk-button-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light.uk-card-badge, .uk-card-badge.uk-section-primary:not(.uk-preserve-color), .uk-card-badge.uk-card-primary.uk-card-body, .uk-card-primary > .uk-card-badge:not([class*=uk-card-media]), .uk-card-badge.uk-overlay-primary {
  background-color: #fff;
  color: #000;
}
.uk-light .uk-close, .uk-section-primary:not(.uk-preserve-color) .uk-close, .uk-card-primary.uk-card-body .uk-close, .uk-card-primary > :not([class*=uk-card-media]) .uk-close, .uk-overlay-primary .uk-close {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-close:hover, .uk-section-primary:not(.uk-preserve-color) .uk-close:hover, .uk-card-primary.uk-card-body .uk-close:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-close:hover, .uk-overlay-primary .uk-close:hover {
  color: #fff;
}
.uk-light .uk-column-divider, .uk-section-primary:not(.uk-preserve-color) .uk-column-divider, .uk-card-primary.uk-card-body .uk-column-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-column-divider, .uk-overlay-primary .uk-column-divider {
  column-rule-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-icon, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-card-primary.uk-card-body .uk-divider-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon, .uk-overlay-primary .uk-divider-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-divider-icon::before, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-card-primary.uk-card-body .uk-divider-icon::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::before, .uk-overlay-primary .uk-divider-icon::before,
.uk-light .uk-divider-icon::after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-card-primary.uk-card-body .uk-divider-icon::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::after,
.uk-overlay-primary .uk-divider-icon::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-small::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-card-primary.uk-card-body .uk-divider-small::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-small::after, .uk-overlay-primary .uk-divider-small::after {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-vertical, .uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-card-primary.uk-card-body .uk-divider-vertical, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-vertical, .uk-overlay-primary .uk-divider-vertical {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-dotnav > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-card-primary.uk-card-body .uk-dotnav > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > *, .uk-overlay-primary .uk-dotnav > * > * {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.9);
}
.uk-light .uk-dotnav > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-card-primary.uk-card-body .uk-dotnav > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :hover, .uk-overlay-primary .uk-dotnav > * > :hover {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}
.uk-light .uk-dotnav > * > :active, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-card-primary.uk-card-body .uk-dotnav > * > :active, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :active, .uk-overlay-primary .uk-dotnav > * > :active {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}
.uk-light .uk-dotnav > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-card-primary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > .uk-active > *, .uk-overlay-primary .uk-dotnav > .uk-active > * {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}
.uk-light .uk-input, .uk-section-primary:not(.uk-preserve-color) .uk-input, .uk-card-primary.uk-card-body .uk-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-input, .uk-overlay-primary .uk-input,
.uk-light .uk-select,
.uk-section-primary:not(.uk-preserve-color) .uk-select,
.uk-card-primary.uk-card-body .uk-select,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select,
.uk-overlay-primary .uk-select,
.uk-light .uk-textarea,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea,
.uk-card-primary.uk-card-body .uk-textarea,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea,
.uk-overlay-primary .uk-textarea {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  background-clip: padding-box;
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input:focus, .uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-input:focus, .uk-overlay-primary .uk-input:focus,
.uk-light .uk-select:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-card-primary.uk-card-body .uk-select:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select:focus,
.uk-overlay-primary .uk-select:focus,
.uk-light .uk-textarea:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-card-primary.uk-card-body .uk-textarea:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea:focus,
.uk-overlay-primary .uk-textarea:focus {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-card-primary.uk-card-body .uk-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-input::placeholder, .uk-overlay-primary .uk-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-textarea::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-card-primary.uk-card-body .uk-textarea::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-textarea::placeholder, .uk-overlay-primary .uk-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-select:not([multiple]):not([size]), .uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-primary > :not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]), .uk-overlay-primary .uk-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-input[list]:hover, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-card-primary.uk-card-body .uk-input[list]:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:hover, .uk-overlay-primary .uk-input[list]:hover,
.uk-light .uk-input[list]:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-card-primary.uk-card-body .uk-input[list]:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-overlay-primary .uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-radio, .uk-section-primary:not(.uk-preserve-color) .uk-radio, .uk-card-primary.uk-card-body .uk-radio, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio, .uk-overlay-primary .uk-radio,
.uk-light .uk-checkbox,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-card-primary.uk-card-body .uk-checkbox,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox,
.uk-overlay-primary .uk-checkbox {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-radio:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-card-primary.uk-card-body .uk-radio:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:focus, .uk-overlay-primary .uk-radio:focus,
.uk-light .uk-checkbox:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-card-primary.uk-card-body .uk-checkbox:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-overlay-primary .uk-checkbox:focus {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #fff;
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked,
.uk-light .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-light .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-checkbox:indeterminate {
  background-color: #fff;
  border-color: #fff;
}
.uk-light .uk-radio:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-card-primary.uk-card-body .uk-radio:checked:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked:focus, .uk-overlay-primary .uk-radio:checked:focus,
.uk-light .uk-checkbox:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-overlay-primary .uk-checkbox:checked:focus,
.uk-light .uk-checkbox:indeterminate:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-overlay-primary .uk-checkbox:indeterminate:focus {
  background-color: white;
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-checkbox:checked, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-card-primary.uk-card-body .uk-checkbox:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked, .uk-overlay-primary .uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-checkbox:indeterminate, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate, .uk-overlay-primary .uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-form-label, .uk-section-primary:not(.uk-preserve-color) .uk-form-label, .uk-card-primary.uk-card-body .uk-form-label, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-label, .uk-overlay-primary .uk-form-label {
  color: #fff;
}
.uk-light .uk-form-icon, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon, .uk-card-primary.uk-card-body .uk-form-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon, .uk-overlay-primary .uk-form-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-form-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-card-primary.uk-card-body .uk-form-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon:hover, .uk-overlay-primary .uk-form-icon:hover {
  color: #fff;
}
.uk-light .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider > :not(.uk-first-column)::before, .uk-overlay-primary .uk-grid-divider > :not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-overlay-primary .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-divider, .uk-section-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-card-primary.uk-card-body .uk-heading-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-divider, .uk-overlay-primary .uk-heading-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-bullet::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-card-primary.uk-card-body .uk-heading-bullet::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-bullet::before, .uk-overlay-primary .uk-heading-bullet::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-line > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-card-primary.uk-card-body .uk-heading-line > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::before, .uk-overlay-primary .uk-heading-line > ::before,
.uk-light .uk-heading-line > ::after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-card-primary.uk-card-body .uk-heading-line > ::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::after,
.uk-overlay-primary .uk-heading-line > ::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link, .uk-card-primary.uk-card-body .uk-icon-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link, .uk-overlay-primary .uk-icon-link {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-icon-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-card-primary.uk-card-body .uk-icon-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:hover, .uk-overlay-primary .uk-icon-link:hover {
  color: #fff;
}
.uk-light .uk-icon-link:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-card-primary.uk-card-body .uk-icon-link:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:active, .uk-overlay-primary .uk-icon-link:active,
.uk-light .uk-active > .uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-card-primary.uk-card-body .uk-active > .uk-icon-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-active > .uk-icon-link,
.uk-overlay-primary .uk-active > .uk-icon-link {
  color: #fff;
}
.uk-light .uk-icon-button, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button, .uk-card-primary.uk-card-body .uk-icon-button, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button, .uk-overlay-primary .uk-icon-button {
  background-color: #f0f0f0;
  color: #000;
}
.uk-light .uk-icon-button.tm-icon-light, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button.tm-icon-light, .uk-card-primary.uk-card-body .uk-icon-button.tm-icon-light, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button.tm-icon-light, .uk-overlay-primary .uk-icon-button.tm-icon-light {
  background: #fff !important;
  color: #000;
}
.uk-light .uk-icon-button.tm-icon-dark, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button.tm-icon-dark, .uk-card-primary.uk-card-body .uk-icon-button.tm-icon-dark, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button.tm-icon-dark, .uk-overlay-primary .uk-icon-button.tm-icon-dark {
  background: #000;
  color: #fff;
}
.uk-light .uk-icon-button.tm-icon-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button.tm-icon-secondary, .uk-card-primary.uk-card-body .uk-icon-button.tm-icon-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button.tm-icon-secondary, .uk-overlay-primary .uk-icon-button.tm-icon-secondary {
  background: #d6da2d;
  color: #000;
}
.uk-light .uk-icon-button:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-card-primary.uk-card-body .uk-icon-button:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:hover, .uk-overlay-primary .uk-icon-button:hover {
  background-color: rgb(214.5, 214.5, 214.5);
  color: #000;
}
.uk-light .uk-icon-button:hover.tm-icon-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover.tm-icon-secondary, .uk-card-primary.uk-card-body .uk-icon-button:hover.tm-icon-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:hover.tm-icon-secondary, .uk-overlay-primary .uk-icon-button:hover.tm-icon-secondary {
  background: #d6da2d;
  color: #000;
}
.uk-light .uk-icon-button:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-card-primary.uk-card-body .uk-icon-button:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:active, .uk-overlay-primary .uk-icon-button:active {
  background-color: #f0f0f0;
  color: #fff;
}
.uk-light .uk-iconnav > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-card-primary.uk-card-body .uk-iconnav > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a, .uk-overlay-primary .uk-iconnav > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-iconnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-card-primary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a:hover, .uk-overlay-primary .uk-iconnav > * > a:hover {
  color: #fff;
}
.uk-light .uk-iconnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > .uk-active > a, .uk-overlay-primary .uk-iconnav > .uk-active > a {
  color: #fff;
}
.uk-light .uk-label, .uk-section-primary:not(.uk-preserve-color) .uk-label, .uk-card-primary.uk-card-body .uk-label, .uk-card-primary > :not([class*=uk-card-media]) .uk-label, .uk-overlay-primary .uk-label {
  background-color: #fff;
  color: #000;
}
.uk-light a.uk-link-muted, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-card-primary.uk-card-body a.uk-link-muted, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted, .uk-overlay-primary a.uk-link-muted,
.uk-light .uk-link-muted a,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-card-primary.uk-card-body .uk-link-muted a,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a,
.uk-overlay-primary .uk-link-muted a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light a.uk-link-muted:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-card-primary.uk-card-body a.uk-link-muted:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted:hover, .uk-overlay-primary a.uk-link-muted:hover,
.uk-light .uk-link-muted a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-card-primary.uk-card-body .uk-link-muted a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-overlay-primary .uk-link-muted a:hover,
.uk-light .uk-link-toggle:hover .uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-muted {
  color: #fff;
}
.uk-light a.uk-link-text:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-card-primary.uk-card-body a.uk-link-text:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-text:hover, .uk-overlay-primary a.uk-link-text:hover,
.uk-light .uk-link-text a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-card-primary.uk-card-body .uk-link-text a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-overlay-primary .uk-link-text a:hover,
.uk-light .uk-link-toggle:hover .uk-link-text,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-text {
  color: #fff;
}
.uk-light a.uk-link-text:hover::before, .uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover::before, .uk-card-primary.uk-card-body a.uk-link-text:hover::before, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-text:hover::before, .uk-overlay-primary a.uk-link-text:hover::before,
.uk-light .uk-link-text a:hover::before,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover::before,
.uk-card-primary.uk-card-body .uk-link-text a:hover::before,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-text a:hover::before,
.uk-overlay-primary .uk-link-text a:hover::before,
.uk-light .uk-link-toggle:hover .uk-link-text::before,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text::before,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text::before,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text::before,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-text::before {
  background-color: #fff;
}
.uk-light a.uk-link-heading:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-card-primary.uk-card-body a.uk-link-heading:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-heading:hover, .uk-overlay-primary a.uk-link-heading:hover,
.uk-light .uk-link-heading a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-card-primary.uk-card-body .uk-link-heading a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-overlay-primary .uk-link-heading a:hover,
.uk-light .uk-link-toggle:hover .uk-link-heading,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-heading {
  color: #fff;
}
.uk-light .uk-list-muted > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-card-primary.uk-card-body .uk-list-muted > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-muted > ::before, .uk-overlay-primary .uk-list-muted > ::before {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-list-emphasis > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-card-primary.uk-card-body .uk-list-emphasis > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-emphasis > ::before, .uk-overlay-primary .uk-list-emphasis > ::before {
  color: #fff !important;
}
.uk-light .uk-list-primary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-card-primary.uk-card-body .uk-list-primary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-primary > ::before, .uk-overlay-primary .uk-list-primary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-secondary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-card-primary.uk-card-body .uk-list-secondary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-secondary > ::before, .uk-overlay-primary .uk-list-secondary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-bullet > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-card-primary.uk-card-body .uk-list-bullet > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-bullet > ::before, .uk-overlay-primary .uk-list-bullet > ::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-list-divider > :nth-child(n+2), .uk-section-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-card-primary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-divider > :nth-child(n+2), .uk-overlay-primary .uk-list-divider > :nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-striped > *:nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > *:nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > *:nth-of-type(odd), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-striped > *:nth-of-type(odd), .uk-overlay-primary .uk-list-striped > *:nth-of-type(odd) {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-striped > :nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-striped > :nth-of-type(odd), .uk-overlay-primary .uk-list-striped > :nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-marker, .uk-section-primary:not(.uk-preserve-color) .uk-marker, .uk-card-primary.uk-card-body .uk-marker, .uk-card-primary > :not([class*=uk-card-media]) .uk-marker, .uk-overlay-primary .uk-marker {
  background: hsl(0, 0%, 125.8774509804%);
  color: #000;
}
.uk-light .uk-marker:hover, .uk-section-primary:not(.uk-preserve-color) .uk-marker:hover, .uk-card-primary.uk-card-body .uk-marker:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-marker:hover, .uk-overlay-primary .uk-marker:hover {
  color: #000;
}
.uk-light .uk-nav-default > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-card-primary.uk-card-body .uk-nav-default > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a, .uk-overlay-primary .uk-nav-default > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a:hover, .uk-overlay-primary .uk-nav-default > li > a:hover {
  color: #fff;
}
.uk-light .uk-nav-default > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li.uk-active > a, .uk-overlay-primary .uk-nav-default > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-header, .uk-overlay-primary .uk-nav-default .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider, .uk-overlay-primary .uk-nav-default .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-default .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a, .uk-overlay-primary .uk-nav-default .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-card-primary.uk-card-body .uk-nav-primary > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a, .uk-overlay-primary .uk-nav-primary > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a:hover, .uk-overlay-primary .uk-nav-primary > li > a:hover {
  color: #fff;
}
.uk-light .uk-nav-primary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li.uk-active > a, .uk-overlay-primary .uk-nav-primary > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header, .uk-overlay-primary .uk-nav-primary .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider, .uk-overlay-primary .uk-nav-primary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-primary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-secondary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a, .uk-overlay-primary .uk-nav-secondary > li > a {
  color: #fff;
}
.uk-light .uk-nav-secondary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover, .uk-overlay-primary .uk-nav-secondary > li > a:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-nav-secondary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-nav-secondary .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary .uk-nav-subtitle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: #fff;
}
.uk-light .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #fff;
}
.uk-light .uk-nav-secondary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-header, .uk-overlay-primary .uk-nav-secondary .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-secondary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-divider, .uk-overlay-primary .uk-nav-secondary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-secondary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-secondary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a:hover {
  color: #fff;
}
.uk-light .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-section-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary > :not([class*=uk-card-media]) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-overlay-primary .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-navbar-nav > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a, .uk-overlay-primary .uk-navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-nav > li:hover > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li:hover > a, .uk-overlay-primary .uk-navbar-nav > li:hover > a,
.uk-light .uk-navbar-nav > li > a[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-overlay-primary .uk-navbar-nav > li > a[aria-expanded=true] {
  color: #fff;
}
.uk-light .uk-navbar-nav > li > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a:active, .uk-overlay-primary .uk-navbar-nav > li > a:active {
  color: #fff;
}
.uk-light .uk-navbar-nav > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li.uk-active > a, .uk-overlay-primary .uk-navbar-nav > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-navbar-item, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-card-primary.uk-card-body .uk-navbar-item, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-item, .uk-overlay-primary .uk-navbar-item {
  color: #fff;
}
.uk-light .uk-navbar-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-card-primary.uk-card-body .uk-navbar-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle, .uk-overlay-primary .uk-navbar-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-card-primary.uk-card-body .uk-navbar-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle:hover, .uk-overlay-primary .uk-navbar-toggle:hover,
.uk-light .uk-navbar-toggle[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-overlay-primary .uk-navbar-toggle[aria-expanded=true] {
  color: #fff;
}
.uk-light .uk-pagination > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-card-primary.uk-card-body .uk-pagination > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > *, .uk-overlay-primary .uk-pagination > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-pagination > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-card-primary.uk-card-body .uk-pagination > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > :hover, .uk-overlay-primary .uk-pagination > * > :hover {
  color: #fff;
}
.uk-light .uk-pagination > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-active > *, .uk-overlay-primary .uk-pagination > .uk-active > * {
  color: #fff;
}
.uk-light .uk-pagination > .uk-disabled > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-disabled > *, .uk-overlay-primary .uk-pagination > .uk-disabled > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-input, .uk-card-primary.uk-card-body .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input, .uk-overlay-primary .uk-search-input {
  color: #fff;
}
.uk-light .uk-search-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-card-primary.uk-card-body .uk-search-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input::placeholder, .uk-overlay-primary .uk-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-card-primary.uk-card-body .uk-search .uk-search-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon, .uk-overlay-primary .uk-search .uk-search-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon:hover, .uk-overlay-primary .uk-search .uk-search-icon:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-default .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input, .uk-overlay-primary .uk-search-default .uk-search-input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-search-default .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus, .uk-overlay-primary .uk-search-default .uk-search-input:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.uk-light .uk-search-navbar .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input, .uk-overlay-primary .uk-search-navbar .uk-search-input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-search-navbar .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input:focus, .uk-overlay-primary .uk-search-navbar .uk-search-input:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.uk-light .uk-search-medium .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-medium .uk-search-input, .uk-card-primary.uk-card-body .uk-search-medium .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-medium .uk-search-input, .uk-overlay-primary .uk-search-medium .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-large .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-card-primary.uk-card-body .uk-search-large .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-large .uk-search-input, .uk-overlay-primary .uk-search-large .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-card-primary.uk-card-body .uk-search-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle, .uk-overlay-primary .uk-search-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-card-primary.uk-card-body .uk-search-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle:hover, .uk-overlay-primary .uk-search-toggle:hover {
  color: #fff;
}
.uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav, .uk-overlay-primary .uk-slidenav {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-slidenav:hover, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-card-primary.uk-card-body .uk-slidenav:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:hover, .uk-overlay-primary .uk-slidenav:hover {
  color: rgba(255, 255, 255, 0.95);
}
.uk-light .uk-slidenav:active, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-card-primary.uk-card-body .uk-slidenav:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:active, .uk-overlay-primary .uk-slidenav:active {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > :first-child, .uk-overlay-primary .uk-subnav > * > :first-child {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > a:hover, .uk-overlay-primary .uk-subnav > * > a:hover {
  color: #fff;
}
.uk-light .uk-subnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-active > a, .uk-overlay-primary .uk-subnav > .uk-active > a {
  color: #fff;
}
.uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-subnav-pill > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > :first-child, .uk-overlay-primary .uk-subnav-pill > * > :first-child {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav-pill > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:hover, .uk-overlay-primary .uk-subnav-pill > * > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.uk-light .uk-subnav-pill > * > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:active, .uk-overlay-primary .uk-subnav-pill > * > a:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.uk-light .uk-subnav-pill > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > .uk-active > a, .uk-overlay-primary .uk-subnav-pill > .uk-active > a {
  background-color: #fff;
  color: #000;
}
.uk-light .uk-subnav > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-disabled > a, .uk-overlay-primary .uk-subnav > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab::before, .uk-section-primary:not(.uk-preserve-color) .uk-tab::before, .uk-card-primary.uk-card-body .uk-tab::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab::before, .uk-overlay-primary .uk-tab::before {
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-tab > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-card-primary.uk-card-body .uk-tab > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a, .uk-overlay-primary .uk-tab > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-card-primary.uk-card-body .uk-tab > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a:hover, .uk-overlay-primary .uk-tab > * > a:hover {
  color: #fff;
}
.uk-light .uk-tab > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-card-primary.uk-card-body .uk-tab > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-active > a, .uk-overlay-primary .uk-tab > .uk-active > a {
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-tab > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-disabled > a, .uk-overlay-primary .uk-tab > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-table-striped > tr:nth-of-type(even):last-child, .uk-section-primary:not(.uk-preserve-color) .uk-table-striped > tr:nth-of-type(even):last-child, .uk-card-primary.uk-card-body .uk-table-striped > tr:nth-of-type(even):last-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-table-striped > tr:nth-of-type(even):last-child, .uk-overlay-primary .uk-table-striped > tr:nth-of-type(even):last-child,
.uk-light .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-card-primary.uk-card-body .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-card-primary > :not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-overlay-primary .uk-table-striped tbody tr:nth-of-type(even):last-child {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-text-lead, .uk-section-primary:not(.uk-preserve-color) .uk-text-lead, .uk-card-primary.uk-card-body .uk-text-lead, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-lead, .uk-overlay-primary .uk-text-lead {
  color: #fff;
}
.uk-light .uk-text-meta, .uk-section-primary:not(.uk-preserve-color) .uk-text-meta, .uk-card-primary.uk-card-body .uk-text-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-meta, .uk-overlay-primary .uk-text-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-text-muted, .uk-section-primary:not(.uk-preserve-color) .uk-text-muted, .uk-card-primary.uk-card-body .uk-text-muted, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-muted, .uk-overlay-primary .uk-text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-text-emphasis, .uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-card-primary.uk-card-body .uk-text-emphasis, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-emphasis, .uk-overlay-primary .uk-text-emphasis {
  color: #fff !important;
}
.uk-light .uk-thumbnav > * > *::after, .uk-section-primary:not(.uk-preserve-color) .uk-thumbnav > * > *::after, .uk-card-primary.uk-card-body .uk-thumbnav > * > *::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-thumbnav > * > *::after, .uk-overlay-primary .uk-thumbnav > * > *::after {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}
.uk-light .uk-totop, .uk-section-primary:not(.uk-preserve-color) .uk-totop, .uk-card-primary.uk-card-body .uk-totop, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop, .uk-overlay-primary .uk-totop {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-totop:hover, .uk-section-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-card-primary.uk-card-body .uk-totop:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:hover, .uk-overlay-primary .uk-totop:hover {
  color: #fff;
}
.uk-light .uk-totop:active, .uk-section-primary:not(.uk-preserve-color) .uk-totop:active, .uk-card-primary.uk-card-body .uk-totop:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:active, .uk-overlay-primary .uk-totop:active {
  color: #fff;
}
.uk-light .uk-logo, .uk-section-primary:not(.uk-preserve-color) .uk-logo, .uk-card-primary.uk-card-body .uk-logo, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo, .uk-overlay-primary .uk-logo {
  color: #fff;
}
.uk-light .uk-logo:hover, .uk-section-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-card-primary.uk-card-body .uk-logo:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo:hover, .uk-overlay-primary .uk-logo:hover {
  color: #fff;
}
.uk-light .uk-logo:has(.uk-logo-inverse) > :not(picture:has(.uk-logo-inverse)):not(.uk-logo-inverse), .uk-section-primary:not(.uk-preserve-color) .uk-logo:has(.uk-logo-inverse) > :not(picture:has(.uk-logo-inverse)):not(.uk-logo-inverse), .uk-card-primary.uk-card-body .uk-logo:has(.uk-logo-inverse) > :not(picture:has(.uk-logo-inverse)):not(.uk-logo-inverse), .uk-card-primary > :not([class*=uk-card-media]) .uk-logo:has(.uk-logo-inverse) > :not(picture:has(.uk-logo-inverse)):not(.uk-logo-inverse), .uk-overlay-primary .uk-logo:has(.uk-logo-inverse) > :not(picture:has(.uk-logo-inverse)):not(.uk-logo-inverse) {
  display: none;
}
.uk-light .uk-logo-inverse, .uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-card-primary.uk-card-body .uk-logo-inverse, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo-inverse, .uk-overlay-primary .uk-logo-inverse {
  display: block;
}
.uk-light .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-accordion-title::before, .uk-overlay-primary .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik00ODAtMzMzIDI0MC01NzNsNTEtNTEgMTg5IDE4OSAxODktMTg5IDUxIDUxLTI0MCAyNDBaIi8+PC9zdmc+");
}
.uk-light .uk-open > .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-open > .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-open > .uk-accordion-title::before, .uk-overlay-primary .uk-open > .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Im0yOTEtMzM2LTUxLTUxIDI0MC0yNDAgMjQwIDI0MC01MSA1MS0xODktMTg5LTE4OSAxODlaIi8+PC9zdmc+");
}
.uk-light .uk-table-striped > tr:nth-of-type(even):last-child, .uk-section-primary:not(.uk-preserve-color) .uk-table-striped > tr:nth-of-type(even):last-child, .uk-card-primary.uk-card-body .uk-table-striped > tr:nth-of-type(even):last-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-table-striped > tr:nth-of-type(even):last-child, .uk-overlay-primary .uk-table-striped > tr:nth-of-type(even):last-child,
.uk-light .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-section-primary:not(.uk-preserve-color) .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-card-primary.uk-card-body .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-card-primary > :not([class*=uk-card-media]) .uk-table-striped tbody tr:nth-of-type(even):last-child,
.uk-overlay-primary .uk-table-striped tbody tr:nth-of-type(even):last-child {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

/*
 * Pass dropbar behind color to JS
 */
* {
  --uk-inverse: initial;
}

.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*=uk-card-media]), .uk-overlay-primary {
  --uk-inverse: light;
}

.uk-dark, .uk-section-default:not(.uk-preserve-color), .uk-section-muted:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-card-default.uk-card-body, .uk-card-default > :not([class*=uk-card-media]), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*=uk-card-media]), .uk-overlay-default, .uk-dropdown, .uk-offcanvas-bar, .uk-navbar-container:not(.uk-navbar-transparent), .uk-navbar-dropdown {
  --uk-inverse: dark;
}

.uk-inverse-light {
  --uk-inverse: light !important;
}

.uk-inverse-dark {
  --uk-inverse: dark !important;
}

.tm-left-header {
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .tm-left-header {
    margin: 0;
  }
}

.tm-right-header {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .tm-right-header {
    margin: 0;
  }
}

@media (max-width: 1199px) {
  .tm-padding-small {
    padding: 100px 20px 50px;
  }
}

@media (max-width: 1599px) {
  #mobile-navigation {
    background: #d6da2d;
    z-index: 10000;
  }
  #mobile-navigation .uk-offcanvas-close {
    right: 20px;
    padding: 0;
    top: 30px;
    background: transparent;
  }
  #mobile-navigation .tm-disturber {
    position: absolute;
    z-index: 10000000;
  }
  #mobile-navigation .tm-disturber > img {
    z-index: 10000001;
    position: absolute;
    left: 16px;
    top: 18px;
  }
  #mobile-navigation .tm-disturber::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 156px;
    height: 70px;
    background: #fff;
  }
  #mobile-navigation .tm-disturber::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    left: 156px;
    border-bottom: 70px solid transparent;
    border-left: 70px solid #fff;
  }
}

.tm-image-overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%) 0 0 no-repeat padding-box;
  opacity: 0.6;
}

.webcare-youtube {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.webcare-youtube iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.tm-disturber {
  width: 356px;
}
.tm-disturber::before {
  content: "";
  position: absolute;
  width: 256px;
}
.tm-disturber::after {
  content: "";
  position: absolute;
}

@media (min-width: 960px) {
  .tm-disturber-bottom-left::before {
    left: 0;
    top: 100px;
    bottom: 0;
    height: 50px;
    width: 256px;
    background: #fff;
  }
  .tm-disturber-bottom-left::after {
    top: 50px;
    bottom: 0;
    left: 256px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #fff;
  }
}
@media (max-width: 1199px) {
  .tm-disturber-bottom-left::before {
    left: 0;
    top: 70px;
    bottom: 0;
    width: 136px;
    height: 50px;
    background: #fff;
  }
  .tm-disturber-bottom-left::after {
    top: 20px;
    bottom: 0;
    left: 136px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #fff;
  }
}

@media (min-width: 960px) {
  .tm-disturber-bottom-right::before {
    right: 0;
    bottom: 0;
    height: 50px;
    background: #fff;
    width: 206px;
  }
  .tm-disturber-bottom-right::after {
    bottom: 0;
    right: 206px;
    width: 25px;
    border-top: 50px solid transparent;
    border-right: 50px solid #fff;
  }
}
@media (max-width: 1199px) {
  .tm-disturber-bottom-right::before {
    right: 0;
    bottom: 0;
    height: 30px;
    background: #fff;
    width: 106px;
  }
  .tm-disturber-bottom-right::after {
    bottom: 0;
    right: 106px;
    width: 15px;
    border-top: 30px solid transparent;
    border-right: 30px solid #fff;
  }
}

@media (min-width: 960px) {
  .tm-disturber-top-left::before {
    left: 0;
    top: 0;
    height: 50px;
    width: 256px;
    background: #fff;
  }
  .tm-disturber-top-left::after {
    top: -50px;
    bottom: 0;
    left: 256px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #fff;
  }
}
@media (max-width: 1199px) {
  .tm-disturber-top-left::before:not(.tm-disturber-navigation) {
    left: 0;
    top: 0;
    bottom: 0;
    width: 136px;
    height: 50px;
    background: #fff;
  }
  .tm-disturber-top-left::after(.tm-disturber-navigation) {
    top: -50px;
    bottom: 0;
    left: 136px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #fff;
  }
}

.tm-disturber-navigation::before {
  left: 0;
  top: 0;
  height: 70px;
  width: 156px;
  background: #fff;
}
.tm-disturber-navigation::after {
  top: -70px;
  bottom: 0;
  left: 156px;
  width: 70px;
  height: 70px;
  border-bottom: 70px solid transparent;
  border-left: 70px solid #fff;
}

.tm-disturber-footer {
  width: 356px;
}
.tm-disturber-footer::before {
  content: "";
  position: absolute;
  width: 256px;
  top: -50px;
  height: 50px;
  left: 0;
  background: #d6da2d;
}
.tm-disturber-footer::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: -50px;
  left: 256px;
  border-top: 50px solid transparent;
  border-left: 50px solid #d6da2d;
}

.tm-disturber-navigation-black::before {
  left: 0;
  top: 0;
  height: 50px;
  width: 156px;
  background: #000;
}
.tm-disturber-navigation-black::after {
  top: -50px;
  bottom: 0;
  left: 156px;
  width: 50px;
  height: 50px;
  border-bottom: 50px solid transparent;
  border-left: 50px solid #000;
}

@media (max-width: 1199px) {
  .tm-disturber-mobile::before {
    left: 0;
    top: 0;
    height: 50px;
    width: 156px;
    background: #fff;
  }
  .tm-disturber-mobile::after {
    top: -50px;
    bottom: 0;
    left: 156px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #fff;
  }
}

.tm-disturber-z-index::after, .tm-disturber-z-index::before {
  z-index: 1;
}

img[data-src][src*="data:image"] {
  background: rgba(0, 0, 0, 0.05);
}

.grecaptcha-badge {
  display: none;
}

p a:not(.uk-link, .uk-button) {
  text-decoration: underline;
}
p a:not(.uk-link, .uk-button):hover:not(.uk-accordion-title), p a:not(.uk-link, .uk-button):focus:not(.uk-accordion-title), p a:not(.uk-link, .uk-button):active:not(.uk-accordion-title) {
  color: #a5a5a5;
}

@media (max-width: 1199px) {
  p {
    margin-bottom: 10px;
  }
}

ul ul {
  list-style-type: disc;
}

.tm-shop-navigation {
  z-index: 2;
}

.tm-shop-checkout .uk-active .tm-icon-button-dark {
  background: #d6da2d;
  color: #000;
}

.tm-align-end {
  align-content: flex-end;
}

.tm-align-center {
  align-content: center;
}

@media (min-width: 640px) {
  .tm-width-category {
    max-width: 200px;
    min-width: 200px;
  }
  .cc-revoke.cc-bottom {
    right: 3em;
    left: unset !important;
  }
}
/* stylelint-disable */
@media (max-width: 414px) {
  .cc-revoke.cc-bot {
    right: unset !important;
  }
}

/* stylelint-enable */
/* stylelint-disable */
/* stylelint-disable */
/* purgecss start ignore */
/**
* General Uppy styles that apply to everything inside the .uppy-Root container
*/
.uppy-Root {
  box-sizing: border-box;
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  position: relative;
  color: #333;
}

[dir=rtl] .uppy-Root, .uppy-Root[dir=rtl] {
  text-align: right;
}

.uppy-Root *, .uppy-Root *:before, .uppy-Root *:after {
  box-sizing: inherit;
}

.uppy-Root [hidden] {
  display: none;
}

.uppy-u-reset {
  -webkit-appearance: none;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  backface-visibility: visible;
  background: none;
  border: medium none currentColor;
  border-collapse: separate;
  border-image: none;
  border-radius: 0;
  border-spacing: 0;
  box-shadow: none;
  clear: none;
  cursor: auto;
  display: inline;
  empty-cells: show;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  list-style: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: medium none invert;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  transition: none 0s ease 0s;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  z-index: auto;
}

[dir=rtl] .uppy-u-reset {
  text-align: right;
}

.uppy-c-textInput {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px 8px;
  background-color: #fff;
}
.uppy-size--md .uppy-c-textInput {
  padding: 8px 10px;
}
.uppy-c-textInput:focus {
  border-color: rgba(34, 117, 215, 0.6);
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.15);
}
[data-uppy-theme=dark] .uppy-c-textInput {
  background-color: #333;
  border-color: #333;
  color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-c-textInput:focus {
  border-color: #525252;
  box-shadow: none;
}

.uppy-c-icon {
  max-width: 100%;
  max-height: 100%;
  fill: currentColor;
  display: inline-block;
  overflow: hidden;
}

.uppy-c-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  user-select: none;
}
[dir=rtl] .uppy-c-btn {
  text-align: center;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.uppy-c-btn::-moz-focus-inner {
  border: 0;
}

.uppy-c-btn-primary {
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 4px;
  background-color: #2275d7;
  color: #fff;
}
.uppy-c-btn-primary:hover {
  background-color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-c-btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.4);
}
.uppy-size--md .uppy-c-btn-primary {
  padding: 13px 22px;
}
[data-uppy-theme=dark] .uppy-c-btn-primary {
  color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-c-btn-primary:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-c-btn-primary::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-c-btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-c-btn-link {
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: transparent;
  color: #525252;
}
.uppy-c-btn-link:hover {
  color: #333;
}
.uppy-c-btn-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
}
.uppy-size--md .uppy-c-btn-link {
  padding: 13px 18px;
}
[data-uppy-theme=dark] .uppy-c-btn-link {
  color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-c-btn-link:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-c-btn-link::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-c-btn-link:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
[data-uppy-theme=dark] .uppy-c-btn-link:hover {
  color: #939393;
}

.uppy-c-btn--small {
  font-size: 0.9em;
  padding: 7px 16px;
  border-radius: 2px;
}
.uppy-size--md .uppy-c-btn--small {
  padding: 8px 10px;
  border-radius: 2px;
}

.uppy-Informer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 1;
  transform: none;
  transition: all 250ms ease-in;
  z-index: 1005;
}

.uppy-Informer[aria-hidden=true] {
  opacity: 0;
  transform: translateY(350%);
  transition: all 300ms ease-in;
  z-index: -1000;
}

.uppy-Informer p {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  padding: 6px 15px;
  background-color: #757575;
  color: #fff;
  border-radius: 18px;
  max-width: 90%;
}
.uppy-size--md .uppy-Informer p {
  font-size: 14px;
  line-height: 1.3;
  max-width: 500px;
  padding: 10px 20px;
}
[data-uppy-theme=dark] .uppy-Informer p {
  background-color: #333;
}

.uppy-Informer span {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #525252;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  top: -1px;
  inset-inline-start: 3px;
  font-size: 10px;
  margin-inline-start: -1px;
}

.uppy-Informer span:hover {
  cursor: help;
}

.uppy-Informer span:after {
  line-height: 1.3;
  word-wrap: break-word;
}

/* -------------------------------------------------------------------
  Microtip

  Modern, lightweight css-only tooltips
  Just 1kb minified and gzipped

  @author Ghosh
  @package Microtip

----------------------------------------------------------------------
  1. Base Styles
  2. Direction Modifiers
  3. Position Modifiers
--------------------------------------------------------------------*/
/* ------------------------------------------------
  [1] Base Styles
-------------------------------------------------*/
.uppy-Root [aria-label][role~=tooltip] {
  /* no important */
  position: relative;
}

.uppy-Root [aria-label][role~=tooltip]::before,
.uppy-Root [aria-label][role~=tooltip]::after {
  /* no important */
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  opacity: 0;
  pointer-events: none;
  transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  transform-origin: top;
}

.uppy-Root [aria-label][role~=tooltip]::before {
  /* no important */
  background-size: 100% auto !important;
  content: "";
}

.uppy-Root [aria-label][role~=tooltip]::after {
  /* no important */
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #ffffff;
  content: attr(aria-label);
  font-size: var(--microtip-font-size, 13px);
  font-weight: var(--microtip-font-weight, normal);
  text-transform: var(--microtip-text-transform, none);
  padding: 0.5em 1em;
  white-space: nowrap;
  box-sizing: content-box;
}

.uppy-Root [aria-label][role~=tooltip]:hover::before,
.uppy-Root [aria-label][role~=tooltip]:hover::after,
.uppy-Root [aria-label][role~=tooltip]:focus::before,
.uppy-Root [aria-label][role~=tooltip]:focus::after {
  /* no important */
  opacity: 1;
  pointer-events: auto;
}

/* ------------------------------------------------
  [2] Position Modifiers
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position|=top]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-bottom: 5px;
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]::after {
  /* no important */
  margin-bottom: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]::before {
  /* no important */
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]:hover::before {
  /* no important */
  transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]::after {
  /* no important */
  transform: translate3d(-50%, 0, 0);
  bottom: 100%;
  left: 50%;
}

.uppy-Root [role~=tooltip][data-microtip-position=top]:hover::after {
  /* no important */
  transform: translate3d(-50%, -5px, 0);
}

/* ------------------------------------------------
  [2.1] Top Left
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=top-left]::after {
  /* no important */
  transform: translate3d(calc(-100% + 16px), 0, 0);
  bottom: 100%;
}

.uppy-Root [role~=tooltip][data-microtip-position=top-left]:hover::after {
  /* no important */
  transform: translate3d(calc(-100% + 16px), -5px, 0);
}

/* ------------------------------------------------
  [2.2] Top Right
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=top-right]::after {
  /* no important */
  transform: translate3d(calc(0% - 16px), 0, 0);
  bottom: 100%;
}

.uppy-Root [role~=tooltip][data-microtip-position=top-right]:hover::after {
  /* no important */
  transform: translate3d(calc(0% - 16px), -5px, 0);
}

/* ------------------------------------------------
  [2.3] Bottom
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::after {
  /* no important */
  margin-top: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::before {
  /* no important */
  transform: translate3d(-50%, -10px, 0);
  bottom: auto;
  left: 50%;
  top: 100%;
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]:hover::before {
  /* no important */
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::after {
  /* no important */
  transform: translate3d(-50%, -10px, 0);
  top: 100%;
  left: 50%;
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom]:hover::after {
  /* no important */
  transform: translate3d(-50%, 0, 0);
}

/* ------------------------------------------------
  [2.4] Bottom Left
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=bottom-left]::after {
  /* no important */
  transform: translate3d(calc(-100% + 16px), -10px, 0);
  top: 100%;
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom-left]:hover::after {
  /* no important */
  transform: translate3d(calc(-100% + 16px), 0, 0);
}

/* ------------------------------------------------
  [2.5] Bottom Right
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=bottom-right]::after {
  /* no important */
  transform: translate3d(calc(0% - 16px), -10px, 0);
  top: 100%;
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom-right]:hover::after {
  /* no important */
  transform: translate3d(calc(0% - 16px), 0, 0);
}

/* ------------------------------------------------
  [2.6] Left
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=left]::before,
.uppy-Root [role~=tooltip][data-microtip-position=left]::after {
  /* no important */
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=left]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}

.uppy-Root [role~=tooltip][data-microtip-position=left]::after {
  /* no important */
  margin-right: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position=left]:hover::before,
.uppy-Root [role~=tooltip][data-microtip-position=left]:hover::after {
  /* no important */
  transform: translate3d(0, -50%, 0);
}

/* ------------------------------------------------
  [2.7] Right
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-position=right]::before,
.uppy-Root [role~=tooltip][data-microtip-position=right]::after {
  /* no important */
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=right]::before {
  /* no important */
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}

.uppy-Root [role~=tooltip][data-microtip-position=right]::after {
  /* no important */
  margin-left: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position=right]:hover::before,
.uppy-Root [role~=tooltip][data-microtip-position=right]:hover::after {
  /* no important */
  transform: translate3d(0, -50%, 0);
}

/* ------------------------------------------------
  [3] Size
-------------------------------------------------*/
.uppy-Root [role~=tooltip][data-microtip-size=small]::after {
  /* no important */
  white-space: initial;
  width: 80px;
}

.uppy-Root [role~=tooltip][data-microtip-size=medium]::after {
  /* no important */
  white-space: initial;
  width: 150px;
}

.uppy-Root [role~=tooltip][data-microtip-size=large]::after {
  /* no important */
  white-space: initial;
  width: 260px;
}

.uppy-StatusBar {
  display: flex;
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  background-color: #fff;
  z-index: 1001;
  transition: height 0.2s;
}
.uppy-size--md .uppy-StatusBar {
  height: 46px;
}
[data-uppy-theme=dark] .uppy-StatusBar {
  background-color: #1f1f1f;
}

.uppy-StatusBar:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
}
[data-uppy-theme=dark] .uppy-StatusBar:before {
  background-color: #757575;
}

.uppy-StatusBar[aria-hidden=true] {
  overflow-y: hidden;
  height: 0;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-StatusBar-progress {
  background-color: #2275d7;
  height: 2px;
  position: absolute;
  z-index: 1001;
  transition: background-color, width 0.3s ease-out;
}
.uppy-StatusBar-progress.is-indeterminate {
  background-size: 64px 64px;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
  animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
}

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 64px 0;
  }
}
.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

.uppy-StatusBar-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1002;
  padding-inline-start: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  height: 100%;
}
.uppy-size--md .uppy-StatusBar-content {
  padding-inline-start: 15px;
}
[data-uppy-theme=dark] .uppy-StatusBar-content {
  color: #eaeaea;
}

.uppy-StatusBar-status {
  line-height: 1.4;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline-end: 0.3em;
}

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1;
}
[data-uppy-theme=dark] .uppy-StatusBar-statusPrimary {
  color: #eaeaea;
}

.uppy-StatusBar-statusSecondary {
  margin-top: 1px;
  font-size: 11px;
  line-height: 1.2;
  display: inline-block;
  color: #757575;
  white-space: nowrap;
}
[data-uppy-theme=dark] .uppy-StatusBar-statusSecondary {
  color: #bbb;
}

.uppy-StatusBar-statusSecondaryHint {
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 5px;
  line-height: 1;
}
.uppy-size--md .uppy-StatusBar-statusSecondaryHint {
  margin-inline-end: 8px;
}

.uppy-StatusBar-statusIndicator {
  position: relative;
  top: 1px;
  color: #525252;
  margin-inline-end: 7px;
}
.uppy-StatusBar-statusIndicator svg {
  vertical-align: text-bottom;
}

.uppy-StatusBar-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: 10px;
  z-index: 1004;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  height: 100%;
  position: static;
  padding: 0 15px;
  background-color: #fafafa;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #1f1f1f;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts {
  height: 90px;
  flex-direction: column;
}
.uppy-size--md .uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts {
  height: 65px;
  flex-direction: row;
}
.uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: column;
  justify-content: center;
}
.uppy-size--md .uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: row;
  justify-content: initial;
}

.uppy-StatusBar-actionCircleBtn {
  line-height: 1;
  cursor: pointer;
  margin: 3px;
  opacity: 0.9;
}
.uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}
.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
.uppy-StatusBar-actionCircleBtn:hover {
  opacity: 1;
}
.uppy-StatusBar-actionCircleBtn:focus {
  border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: inherit;
  color: #2275d7;
}
.uppy-size--md .uppy-StatusBar-actionBtn {
  font-size: 11px;
}

.uppy-StatusBar-actionBtn--disabled {
  opacity: 0.4;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--disabled {
  opacity: 0.7;
}

.uppy-StatusBar-actionBtn--retry {
  height: 16px;
  border-radius: 8px;
  margin-inline-end: 6px;
  background-color: #ff4b23;
  line-height: 1;
  color: #fff;
  padding: 1px 6px 3px 18px;
  position: relative;
}
.uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
.uppy-StatusBar-actionBtn--retry:hover {
  background-color: rgb(249.2, 45.3090909091, 0);
}
.uppy-StatusBar-actionBtn--retry svg {
  position: absolute;
  top: 3px;
  inset-inline-start: 6px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  font-size: 14px;
  width: 100%;
  padding: 15px 10px;
  color: #fff;
  background-color: #1bb240;
  line-height: 1;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: rgb(23.6414634146, 155.8585365854, 56.0390243902);
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1c8b37;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: rgb(23.7245508982, 117.7754491018, 46.6017964072);
}

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  padding: 13px 22px;
  width: auto;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  cursor: not-allowed;
  background-color: #1bb240;
}

[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1c8b37;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  background-color: transparent;
  color: #2275d7;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-inline-end: 3px;
  padding-inline-start: 3px;
  padding-bottom: 1px;
  border-radius: 3px;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-StatusBar-actionBtn--done {
  line-height: 1;
  border-radius: 3px;
  padding: 7px 8px;
}
.uppy-StatusBar-actionBtn--done:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--done::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--done:hover {
  color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-StatusBar-actionBtn--done:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--done:focus {
  background-color: #333;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--done {
  color: #02baf2;
}

.uppy-size--md .uppy-StatusBar-actionBtn--done {
  font-size: 14px;
}

.uppy-StatusBar-serviceMsg {
  font-size: 11px;
  line-height: 1.1;
  color: #000;
  padding-left: 10px;
}
.uppy-size--md .uppy-StatusBar-serviceMsg {
  font-size: 14px;
  padding-left: 15px;
}
[data-uppy-theme=dark] .uppy-StatusBar-serviceMsg {
  color: #eaeaea;
}

.uppy-StatusBar-serviceMsg-ghostsIcon {
  opacity: 0.5;
  vertical-align: text-bottom;
  position: relative;
  top: 2px;
  left: 6px;
  width: 10px;
}
.uppy-size--md .uppy-StatusBar-serviceMsg-ghostsIcon {
  width: 15px;
  left: 10px;
  top: 1px;
}

.uppy-StatusBar-details {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #939393;
  border-radius: 50%;
  position: relative;
  top: 0;
  inset-inline-start: 2px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  cursor: help;
}

.uppy-StatusBar-details:after {
  line-height: 1.3;
  word-wrap: break-word;
}

.uppy-StatusBar-spinner {
  animation-name: uppy-StatusBar-spinnerAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-inline-end: 10px;
  fill: #2275d7;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623;
}

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px;
}
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list::after {
  content: "";
  flex: auto;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
  width: 50%;
  position: relative;
  margin: 0;
}
.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
  width: 33.3333%;
}
.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
  width: 25%;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem::before {
  content: "";
  padding-top: 100%;
  display: block;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img, .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg {
  opacity: 0.85;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.5;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(147, 147, 147, 0.2);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(234, 234, 234, 0.2);
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(0, 0, 0, 0.7);
  width: 30%;
  height: 30%;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(255, 255, 255, 0.8);
}
.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner {
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;
  text-align: center;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
}
.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.9);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px rgba(170, 225, 255, 0.7);
}
.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 26px;
  height: 26px;
  background-color: #2275d7;
  border-radius: 50%;
  z-index: 1002;
  opacity: 0;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox:after {
  width: 12px;
  height: 7px;
  inset-inline-start: 7px;
  top: 8px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
  opacity: 1;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  margin: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.6;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox {
  margin-inline-end: 15px;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox:focus {
  border: 1px solid #2275d7;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
  outline: none;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox::after {
  opacity: 0;
  height: 5px;
  width: 9px;
  inset-inline-start: 3px;
  top: 4px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox:focus {
  border-color: rgba(2, 186, 242, 0.7);
  box-shadow: 0 0 0 3px rgba(2, 186, 242, 0.2);
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked::after {
  opacity: 1;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 2px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  outline: none;
  text-decoration: underline;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img, .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  margin-inline-end: 8px;
  max-width: 20px;
  max-height: 20px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
  margin-inline-end: 7px;
}

.uppy-ProviderBrowserItem-fakeCheckbox {
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}
.uppy-ProviderBrowserItem-fakeCheckbox:disabled {
  cursor: default;
}
.uppy-ProviderBrowserItem-fakeCheckbox::after {
  content: "";
  position: absolute;
  cursor: pointer;
  border-left: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  transform: rotate(-45deg);
}
.uppy-ProviderBrowserItem-fakeCheckbox:disabled::after {
  cursor: default;
}
[data-uppy-theme=dark] .uppy-ProviderBrowserItem-fakeCheckbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme=dark] .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
[data-uppy-theme=dark] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}
.uppy-size--md .uppy-SearchProvider-input {
  margin-bottom: 20px;
}

.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}
.uppy-size--md .uppy-SearchProvider-searchButton {
  padding: 13px 30px;
}

.uppy-DashboardContent-panelBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
[data-uppy-theme=dark] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  flex: 1;
  color: #939393;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px;
}

.uppy-Provider-authTitle {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 15px;
  max-width: 500px;
  text-align: center;
  color: #757575;
}
.uppy-size--md .uppy-Provider-authTitle {
  font-size: 20px;
}
[data-uppy-theme=dark] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-breadcrumbs {
  flex: 1;
  color: #525252;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: start;
}
.uppy-size--md .uppy-Provider-breadcrumbs {
  margin-bottom: 0;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

.uppy-Provider-breadcrumbsIcon {
  display: inline-block;
  color: #525252;
  vertical-align: middle;
  margin-inline-end: 4px;
  line-height: 1;
}

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252;
}

.uppy-Provider-breadcrumbs button {
  display: inline-block;
  line-height: inherit;
  padding: 4px;
  border-radius: 3px;
}
.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}
.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}
.uppy-Provider-breadcrumbs button:hover {
  color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-Provider-breadcrumbs button:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}
.uppy-Provider-breadcrumbs button:hover {
  text-decoration: underline;
  cursor: pointer;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
}

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0;
  font-weight: 500;
  color: #333;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

.uppy-ProviderBrowser-user:after {
  content: "·";
  position: relative;
  inset-inline-start: 4px;
  color: #939393;
  font-weight: normal;
}

.uppy-ProviderBrowser-header {
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  padding: 7px 15px;
  background-color: #fafafa;
  z-index: 1001;
  color: #757575;
  line-height: 1.4;
  font-size: 12px;
}
.uppy-size--md .uppy-ProviderBrowser-headerBar {
  display: flex;
  align-items: center;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  text-align: center;
  display: block;
  justify-content: center;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  flex: none;
  display: inline-block;
  vertical-align: middle;
}

.uppy-ProviderBrowser-search {
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-search {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchIcon {
  position: absolute;
  width: 12px;
  height: 12px;
  inset-inline-start: 16px;
  z-index: 1002;
  color: #bbb;
}

.uppy-ProviderBrowser-searchInput {
  width: 100%;
  height: 30px;
  background-color: transparent;
  outline: 0;
  font-family: "Roc Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  line-height: 1.4;
  border: 0;
  margin: 0 8px;
  padding-inline-start: 27px;
  z-index: 1001;
  border-radius: 4px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput {
  background-color: #1f1f1f;
  color: #eaeaea;
}

.uppy-ProviderBrowser-searchInput:focus {
  outline: 0;
  background-color: #f4f4f4;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-searchClose {
  position: absolute;
  width: 22px;
  height: 22px;
  padding: 6px;
  inset-inline-end: 12px;
  top: 4px;
  z-index: 1002;
  color: #939393;
  cursor: pointer;
}
.uppy-ProviderBrowser-searchClose:hover {
  color: #757575;
}

.uppy-ProviderBrowser-searchClose svg {
  vertical-align: text-top;
}

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
  cursor: pointer;
  line-height: inherit;
  color: #2275d7;
  padding: 4px;
  border-radius: 3px;
}
.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}
.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}
.uppy-ProviderBrowser-userLogout:hover {
  color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-ProviderBrowser-userLogout:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}
.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  flex: 1;
  position: relative;
}

.uppy-ProviderBrowser-list {
  flex: 1;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-spacing: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
}

.uppy-ProviderBrowser-footer {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 65px;
  border-top: 1px solid #eaeaea;
  padding: 0 15px;
}
.uppy-ProviderBrowser-footer button {
  margin-inline-end: 8px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-Dashboard-Item-previewInnerWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.uppy-size--md .uppy-Dashboard-Item-previewInnerWrap {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.uppy-Dashboard-Item-previewInnerWrap:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
  z-index: 1001;
}

.uppy-Dashboard-Item-previewLink {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1002;
}
.uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px rgb(117.7108433735, 170.7108433735, 233.2891566265);
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px rgb(1.1639344262, 108.2459016393, 140.8360655738);
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateZ(0);
  border-radius: 3px;
}

.uppy-Dashboard-Item-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  color: #fff;
  text-align: center;
  width: 120px;
  transition: all 0.35 ease;
}

.uppy-Dashboard-Item-progressIndicator {
  display: inline-block;
  width: 38px;
  height: 38px;
  opacity: 0.9;
}
.uppy-size--md .uppy-Dashboard-Item-progressIndicator {
  width: 55px;
  height: 55px;
}

button.uppy-Dashboard-Item-progressIndicator {
  cursor: pointer;
}
button.uppy-Dashboard-Item-progressIndicator:focus {
  outline: none;
}
button.uppy-Dashboard-Item-progressIndicator::-moz-focus-inner {
  border: 0;
}
button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--bg,
button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--retry {
  fill: rgb(117.7108433735, 170.7108433735, 233.2891566265);
}

.uppy-Dashboard-Item-progressIcon--circle {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-progressIcon--bg {
  stroke: rgba(255, 255, 255, 0.4);
}

.uppy-Dashboard-Item-progressIcon--progress {
  stroke: #fff;
  transition: stroke-dashoffset 0.5s ease-out;
}

.uppy-Dashboard-Item-progressIcon--play {
  stroke: #fff;
  fill: #fff;
  transition: all 0.2s;
}

.uppy-Dashboard-Item-progressIcon--cancel {
  fill: #fff;
  transition: all 0.2s;
}

.uppy-Dashboard-Item-progressIcon--pause {
  stroke: #fff;
  fill: #fff;
  transition: all 0.2s;
}

.uppy-Dashboard-Item-progressIcon--check {
  fill: #fff;
  transition: all 0.2s;
}

.uppy-Dashboard-Item-progressIcon--retry {
  fill: #fff;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  transform: initial;
  top: -9px;
  inset-inline-end: -8px;
  inset-inline-start: initial;
  width: auto;
}

.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
}
.uppy-size--md .uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 28px;
  height: 28px;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1;
}
.uppy-size--md .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 22px;
  height: 22px;
}

.uppy-Dashboard-Item.is-processing .uppy-Dashboard-Item-progress {
  opacity: 0;
}

.uppy-Dashboard-Item-fileInfo {
  padding-inline-end: 5px;
}

.uppy-Dashboard-Item-name {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 5px;
  word-break: break-all;
  word-wrap: anywhere;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-name {
  color: #eaeaea;
}

.uppy-Dashboard-Item-status {
  font-size: 11px;
  line-height: 1;
  font-weight: normal;
  color: #757575;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-status {
  color: #bbb;
}

.uppy-Dashboard-Item-statusSize {
  display: inline-block;
  vertical-align: bottom;
  text-transform: uppercase;
}

.uppy-Dashboard-Item-reSelect {
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  color: #2275d7;
}

.uppy-Dashboard-Item-action {
  cursor: pointer;
  color: #939393;
}
.uppy-Dashboard-Item-action:focus {
  outline: none;
}
.uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-Dashboard-Item-action:hover {
  opacity: 1;
  color: #1f1f1f;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action {
  color: #cfcfcf;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:hover {
  color: #eaeaea;
}

.uppy-Dashboard-Item-action--remove {
  color: #1f1f1f;
  opacity: 0.95;
}
.uppy-Dashboard-Item-action--remove:hover {
  opacity: 1;
  color: #000;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action--remove {
  color: #525252;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action--remove:hover {
  color: #333;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-actionWrapper {
  display: flex;
  align-items: center;
}
.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-action {
  width: 22px;
  height: 22px;
  padding: 3px;
  margin-left: 3px;
}
.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-action:focus {
  border-radius: 3px;
}

.uppy-size--md .uppy-Dashboard-Item-action--copyLink,
.uppy-size--md .uppy-Dashboard-Item-action--edit {
  width: 16px;
  height: 16px;
  padding: 0;
}
.uppy-size--md .uppy-Dashboard-Item-action--copyLink:focus,
.uppy-size--md .uppy-Dashboard-Item-action--edit:focus {
  border-radius: 3px;
}
.uppy-size--md .uppy-Dashboard-Item-action--remove {
  z-index: 1002;
  position: absolute;
  top: -8px;
  inset-inline-end: -8px;
  width: 18px;
  height: 18px;
  padding: 0;
}
.uppy-size--md .uppy-Dashboard-Item-action--remove:focus {
  border-radius: 50%;
}

.uppy-Dashboard-Item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  padding-inline-end: 0;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item {
  border-bottom: 1px solid #333;
}
.uppy-size--md .uppy-Dashboard-Item {
  position: relative;
  display: block;
  float: inline-start;
  margin: 5px 15px;
  padding: 0;
  /* When changing width: also update `itemsPerRow` values in `src/components/Dashboard.js`. */
  width: calc(33.333% - 15px - 15px);
  height: 215px;
  border-bottom: 0;
}
.uppy-size--lg .uppy-Dashboard-Item {
  margin: 5px 15px;
  /* When changing width: also update `itemsPerRow` values in `src/components/Dashboard.js`. */
  width: calc(25% - 15px - 15px);
  height: 190px;
}
.uppy-size--xl .uppy-Dashboard-Item {
  /* When changing width: also update `itemsPerRow` values in `src/components/Dashboard.js`. */
  width: calc(20% - 15px - 15px);
  height: 210px;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-previewInnerWrap {
  opacity: 0.2;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-name {
  opacity: 0.7;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='39' viewBox='0 0 35 39'%3E%3Cpath d='M1.708 38.66c1.709 0 3.417-3.417 6.834-3.417 3.416 0 5.125 3.417 8.61 3.417 3.348 0 5.056-3.417 8.473-3.417 4.305 0 5.125 3.417 6.833 3.417.889 0 1.709-.889 1.709-1.709v-19.68C34.167-5.757 0-5.757 0 17.271v19.68c0 .82.888 1.709 1.708 1.709zm8.542-17.084a3.383 3.383 0 01-3.417-3.416 3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.417 3.417 3.383 3.383 0 01-3.417 3.416zm13.667 0A3.383 3.383 0 0120.5 18.16a3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.416 3.417 3.383 3.383 0 01-3.416 3.416z' fill='%2523000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 10px;
  background-size: 25px;
  z-index: 1005;
  opacity: 0.5;
}
.uppy-size--md .uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview:before {
  background-size: 40px;
  background-position: 50% 50%;
}

.uppy-Dashboard-Item-preview {
  position: relative;
}
.uppy-Dashboard:not(.uppy-size--md) .uppy-Dashboard-Item-preview {
  flex-shrink: 0;
  flex-grow: 0;
  width: 50px;
  height: 50px;
}
.uppy-size--md .uppy-Dashboard-Item-preview {
  width: 100%;
  height: 140px;
}
.uppy-size--lg .uppy-Dashboard-Item-preview {
  height: 120px;
}
.uppy-size--xl .uppy-Dashboard-Item-preview {
  height: 140px;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  flex-grow: 1;
  padding-inline-end: 8px;
  padding-inline-start: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uppy-size--md .uppy-Dashboard-Item-fileInfoAndButtons {
  align-items: flex-start;
  width: 100%;
  padding: 0;
  padding-top: 9px;
}

.uppy-Dashboard-Item-fileInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.uppy-Dashboard-Item-actionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-Dashboard-Item.is-inprogress .uppy-Dashboard-Item-previewInnerWrap:after,
.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-previewInnerWrap:after {
  display: block;
}

.uppy-Dashboard-Item.is-inprogress:not(.is-resumable) .uppy-Dashboard-Item-action--remove {
  display: none;
}

.uppy-Dashboard-Item-errorDetails {
  line-height: 12px;
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background-color: #939393;
  border-radius: 50%;
  position: relative;
  top: -1px;
  inset-inline-start: 6px;
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  cursor: help;
}

.uppy-Dashboard-Item-errorDetails:after {
  line-height: 1.3;
  word-wrap: break-word;
}

.uppy-Dashboard-FileCard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.uppy-Dashboard-FileCard-preview {
  height: 60%;
  flex-grow: 0;
  flex-shrink: 1;
  min-height: 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-preview {
  background-color: #333;
  border-bottom: 0;
}

.uppy-Dashboard-FileCard-preview img.uppy-Dashboard-Item-previewImg {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  flex: 0 0 auto;
  border-radius: 3px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.uppy-Dashboard-FileCard-edit {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 7px 15px;
  border-radius: 50px;
}
.uppy-Dashboard-FileCard-edit:focus {
  outline: none;
}
.uppy-Dashboard-FileCard-edit::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-FileCard-edit:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-Dashboard-FileCard-edit:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.uppy-Dashboard-FileCard-info {
  height: 40%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 30px 20px 20px 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-info {
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-fieldset {
  font-size: 0;
  border: 0;
  padding: 0;
  max-width: 640px;
  margin: auto;
  margin-bottom: 12px;
}

.uppy-Dashboard-FileCard-label {
  display: inline-block;
  vertical-align: middle;
  width: 22%;
  font-size: 12px;
  color: #525252;
}
.uppy-size--md .uppy-Dashboard-FileCard-label {
  font-size: 14px;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-label {
  color: #eaeaea;
}

.uppy-Dashboard-FileCard-input {
  display: inline-block;
  vertical-align: middle;
  width: 78%;
}

.uppy-Dashboard-FileCard-actions {
  height: 55px;
  flex-shrink: 0;
  flex-grow: 0;
  border-top: 1px solid #eaeaea;
  padding: 0 15px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
}
.uppy-size--md .uppy-Dashboard-FileCard-actions {
  height: 65px;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-actions {
  border-top: 1px solid #333;
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-actionsBtn {
  margin-inline-end: 10px;
}

.uppy-transition-slideDownUp-enter {
  opacity: 0.01;
  transform: translate3d(0, -105%, 0);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  opacity: 0.01;
  transform: translate3d(0, -105%, 0);
}

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0;
  }
}
@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
}
.uppy-Dashboard--modal {
  z-index: 1001;
}

.uppy-Dashboard--modal[aria-hidden=true] {
  display: none;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard-isFixed {
  overflow: hidden;
  height: 100vh;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.uppy-Dashboard-inner {
  position: relative;
  background-color: #fafafa;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
.uppy-size--md .uppy-Dashboard-inner {
  min-height: auto;
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard-inner {
    width: 750px;
    height: 550px;
  }
}
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1002;
}
[data-uppy-theme=dark] .uppy-Dashboard-inner {
  background-color: #1f1f1f;
}
.uppy-Dashboard--isDisabled .uppy-Dashboard-inner {
  cursor: not-allowed;
}

.uppy-Dashboard-innerWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  opacity: 0;
}
.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1;
}
.uppy-Dashboard--isDisabled .uppy-Dashboard-innerWrap {
  pointer-events: none;
  opacity: 0.6;
  filter: grayscale(100%);
  user-select: none;
}

.uppy-Dashboard--isDisabled .uppy-ProviderIconBg {
  fill: #9f9f9f;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  position: fixed;
  top: 35px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: none;
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15);
  }
}

.uppy-Dashboard-close {
  display: block;
  position: absolute;
  top: -33px;
  inset-inline-end: -2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  font-size: 27px;
  z-index: 1005;
}
.uppy-Dashboard-close:focus {
  outline: none;
}
.uppy-Dashboard-close::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-close:focus {
  color: rgb(139.7289156627, 184.2289156627, 236.7710843373);
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard-close {
    font-size: 35px;
    top: -10px;
    inset-inline-end: -35px;
  }
}

.uppy-Dashboard-serviceMsg {
  background-color: #FFFBF7;
  border-top: 1px solid #edd4b9;
  border-bottom: 1px solid #edd4b9;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  padding: 12px 0;
  position: relative;
  top: -1px;
  z-index: 1004;
}
.uppy-size--md .uppy-Dashboard-serviceMsg {
  font-size: 14px;
  line-height: 1.4;
}
[data-uppy-theme=dark] .uppy-Dashboard-serviceMsg {
  background-color: #1f1f1f;
  color: #eaeaea;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.uppy-Dashboard-serviceMsg-title {
  display: block;
  margin-bottom: 4px;
  padding-left: 42px;
  line-height: 1;
}

.uppy-Dashboard-serviceMsg-text {
  padding: 0 15px;
}

.uppy-Dashboard-serviceMsg-actionBtn {
  font-size: inherit;
  font-weight: inherit;
  vertical-align: initial;
  color: #2275d7;
}
[data-uppy-theme=dark] .uppy-Dashboard-serviceMsg-actionBtn {
  color: rgba(2, 186, 242, 0.9);
}

.uppy-Dashboard-serviceMsg-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.uppy-Dashboard-AddFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;
}
[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  margin: 7px;
  height: calc(100% - 14px);
  border-radius: 3px;
  border: 1px dashed #dfdfdf;
}
.uppy-Dashboard-AddFilesPanel .uppy-Dashboard-AddFiles {
  border: none;
  height: calc(100% - 14px - 40px);
}
.uppy-Dashboard--modal .uppy-Dashboard-AddFiles {
  border-color: #cfcfcf;
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFiles {
  border-color: #757575;
}

.uppy-Dashboard-AddFiles-info {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: auto;
  display: none;
}
.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: block;
}
.uppy-size--md .uppy-Dashboard-AddFiles-info {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  padding-top: 30px;
  padding-bottom: 0;
}
[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-info {
  margin-top: 0;
}

.uppy-Dashboard-browse {
  cursor: pointer;
  color: rgba(34, 117, 215, 0.9);
}
.uppy-Dashboard-browse:focus {
  outline: none;
}
.uppy-Dashboard-browse::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #2275d7;
}
[data-uppy-theme=dark] .uppy-Dashboard-browse {
  color: rgba(2, 186, 242, 0.9);
}
[data-uppy-theme=dark] .uppy-Dashboard-browse:hover, [data-uppy-theme=dark] .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #02baf2;
}

.uppy-Dashboard-browseBtn {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 5px;
  width: 100%;
}
.uppy-size--md .uppy-Dashboard-browseBtn {
  font-size: 15px;
  width: auto;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 13px 44px;
}

.uppy-Dashboard-AddFiles-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 2px;
  padding: 2px 0;
  width: 100%;
}
.uppy-size--md .uppy-Dashboard-AddFiles-list {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 600px;
  overflow-y: visible;
  margin-top: 15px;
  padding-top: 0;
  flex: none;
}

.uppy-DashboardTab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-DashboardTab {
  border-bottom: 1px solid #333;
}
.uppy-size--md .uppy-DashboardTab {
  display: inline-block;
  width: initial;
  margin-bottom: 10px;
  border-bottom: none;
}

.uppy-DashboardTab-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  color: #525252;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 15px;
}
.uppy-DashboardTab-btn:focus {
  outline: none;
}
.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}
.uppy-size--md .uppy-DashboardTab-btn {
  width: 86px;
  margin-inline-end: 1px;
  flex-direction: column;
  padding: 10px 3px;
  border-radius: 5px;
}
[data-uppy-theme=dark] .uppy-DashboardTab-btn {
  color: #eaeaea;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6;
}
[data-uppy-theme=dark] .uppy-DashboardTab-btn:hover {
  background-color: #333;
}

.uppy-DashboardTab-btn:active,
.uppy-DashboardTab-btn:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardTab-btn:active,
[data-uppy-theme=dark] .uppy-DashboardTab-btn:focus {
  background-color: #525252;
}

.uppy-DashboardTab-btn svg {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: text-top;
  overflow: hidden;
  transition: transform ease-in-out 0.15s;
  margin-inline-end: 10px;
}
.uppy-size--md .uppy-DashboardTab-btn svg {
  margin-inline-end: 0;
}

.uppy-DashboardTab-name {
  font-size: 14px;
  font-weight: 500;
}
.uppy-size--md .uppy-DashboardTab-name {
  font-size: 11px;
  line-height: 15px;
  margin-top: 8px;
  margin-bottom: 0;
}

.uppy-DashboardTab svg {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}
.uppy-size--md .uppy-DashboardTab svg {
  width: 30px;
  height: 30px;
}

.uppy-Dashboard-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uppy-DashboardContent-bar {
  flex-shrink: 0;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  z-index: 1004;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafafa;
}
.uppy-size--md .uppy-DashboardContent-bar {
  height: 50px;
  padding: 0 15px;
}
[data-uppy-theme=dark] .uppy-DashboardContent-bar {
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
}

.uppy-DashboardContent-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  font-weight: 500;
  width: 100%;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  margin: auto;
}
.uppy-size--md .uppy-DashboardContent-title {
  font-size: 14px;
  line-height: 50px;
  max-width: 300px;
}
[data-uppy-theme=dark] .uppy-DashboardContent-title {
  color: #eaeaea;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #2275d7;
  padding: 7px 6px;
  margin-inline-start: -6px;
}
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
  outline: none;
}
.uppy-DashboardContent-back::-moz-focus-inner,
.uppy-DashboardContent-save::-moz-focus-inner {
  border: 0;
}
.uppy-DashboardContent-back:hover,
.uppy-DashboardContent-save:hover {
  color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardContent-back:focus,
[data-uppy-theme=dark] .uppy-DashboardContent-save:focus {
  background-color: #333;
}
.uppy-size--md .uppy-DashboardContent-back,
.uppy-size--md .uppy-DashboardContent-save {
  font-size: 14px;
}
[data-uppy-theme=dark] .uppy-DashboardContent-back,
[data-uppy-theme=dark] .uppy-DashboardContent-save {
  color: #02baf2;
}

.uppy-DashboardContent-addMore {
  background: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: 0;
  color: inherit;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  color: #2275d7;
  width: 29px;
  height: 29px;
  padding: 7px 8px;
  margin-inline-end: -5px;
}
.uppy-DashboardContent-addMore:focus {
  outline: none;
}
.uppy-DashboardContent-addMore::-moz-focus-inner {
  border: 0;
}
.uppy-DashboardContent-addMore:hover {
  color: rgb(27.0361445783, 93.0361445783, 170.9638554217);
}
.uppy-DashboardContent-addMore:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardContent-addMore:focus {
  background-color: #333;
}
.uppy-size--md .uppy-DashboardContent-addMore {
  font-size: 14px;
  width: auto;
  height: auto;
  margin-inline-end: -8px;
}
[data-uppy-theme=dark] .uppy-DashboardContent-addMore {
  color: #02baf2;
}

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
  margin-inline-end: 4px;
}
.uppy-size--md .uppy-DashboardContent-addMore svg {
  width: 11px;
  height: 11px;
}

.uppy-DashboardContent-addMoreCaption {
  display: none;
}
.uppy-size--md .uppy-DashboardContent-addMoreCaption {
  display: inline;
}

.uppy-DashboardContent-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(244.8, 244.8, 244.8);
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.uppy-Dashboard-AddFilesPanel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fafafa;
  background: linear-gradient(0deg, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1005;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFilesPanel {
  background-color: #333;
  background: linear-gradient(0deg, #1f1f1f 35%, rgba(31, 31, 31, 0.85) 100%);
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  filter: blur(2px);
}

.uppy-Dashboard-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12%;
}

.uppy-Dashboard-progressBarContainer.is-active {
  z-index: 1004;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-StatusBar-additionalInfo {
  display: none;
}

.uppy-Dashboard-filesContainer {
  position: relative;
  overflow-y: hidden;
  margin: 0;
  flex: 1;
}
.uppy-Dashboard-filesContainer:after {
  content: "";
  display: table;
  clear: both;
}

.uppy-Dashboard-files {
  margin: 0;
  padding: 0 0 10px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}
.uppy-size--md .uppy-Dashboard-files {
  padding-top: 10px;
}

.uppy-Dashboard-dropFilesHereHint {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  padding-top: 90px;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  z-index: 2000;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: #757575;
  font-size: 16px;
}
[data-uppy-theme=dark] .uppy-Dashboard-dropFilesHereHint {
  color: #bbb;
  border-color: #02baf2;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%2302BAF2' fill-rule='nonzero'/%3E%3C/svg%3E");
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible;
}
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-serviceMsg {
  opacity: 0.15;
}
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-AddFiles {
  opacity: 0.03;
}

.uppy-Dashboard-AddFiles-title {
  font-size: 17px;
  line-height: 1.35;
  font-weight: 500;
  color: #000;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: inline-start;
  padding: 0 15px;
  width: 100%;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title {
  font-size: 25px;
  margin-top: 5px;
  font-weight: 400;
  text-align: center;
  max-width: 480px;
}
[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  text-align: center;
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFiles-title {
  color: #eaeaea;
}
.uppy-Dashboard-AddFiles-title button {
  font-weight: 500;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title button {
  font-weight: 400;
}

.uppy-Dashboard-note {
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  color: #757575;
  max-width: 350px;
  margin: auto;
  padding: 0 15px;
}
.uppy-size--md .uppy-Dashboard-note {
  font-size: 15px;
  line-height: 1.35;
  max-width: 600px;
}
[data-uppy-theme=dark] .uppy-Dashboard-note {
  color: #cfcfcf;
}

a.uppy-Dashboard-poweredBy {
  display: inline-block;
  text-align: center;
  font-size: 11px;
  color: #939393;
  text-decoration: none;
  margin-top: 8px;
}

.uppy-Dashboard-poweredByIcon {
  stroke: #939393;
  fill: none;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  top: 1px;
  opacity: 0.9;
  vertical-align: text-top;
}

.uppy-Dashboard-Item-previewIcon {
  width: 25px;
  height: 25px;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uppy-size--md .uppy-Dashboard-Item-previewIcon {
  width: 38px;
  height: 38px;
}
.uppy-Dashboard-Item-previewIcon svg {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-previewIconWrap {
  height: 76px;
  max-height: 75%;
  position: relative;
}

.uppy-Dashboard-Item-previewIconBg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
}

.uppy-Dashboard-upload {
  position: relative;
  width: 50px;
  height: 50px;
}
.uppy-size--md .uppy-Dashboard-upload {
  width: 60px;
  height: 60px;
}

.uppy-Dashboard-upload .uppy-c-icon {
  position: relative;
  top: 1px;
  width: 50%;
}

.uppy-Dashboard-uploadCount {
  position: absolute;
  top: -12px;
  inset-inline-end: -12px;
  background-color: #1bb240;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 8px;
}
.uppy-size--md .uppy-Dashboard-uploadCount {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 9px;
}

.uppy-size--md .uppy-Dashboard-Item {
  float: left !important;
}

input::placeholder {
  color: #a5a5a5 !important;
}

/* purgecss end ignore */
.uppy-Dashboard-browse {
  color: #6f7663;
}

.tm-contact-address {
  padding: 185px 40px 185px 200px;
}
@media (max-width: 1199px) {
  .tm-contact-address {
    padding: 80px;
  }
}
@media (max-width: 959px) {
  .tm-contact-address {
    padding: 30px;
  }
}