@use "sass:color";
@use "sass:math";
@use "@/scss/utils/variables" as base;

// Load deprecated components
// ========================================================================

$deprecated:                                      false !default;


// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

$breakpoint-small:                                base.$sm !default; // Phone landscape
$breakpoint-medium:                               base.$md !default; // Tablet Landscape
$breakpoint-large:                                base.$lg !default; // Desktop
$breakpoint-xlarge:                               base.$xl !default; // Large Screens

$breakpoint-xsmall-max:                           ($breakpoint-small - 1) !default;
$breakpoint-small-max:                            ($breakpoint-medium - 1) !default;
$breakpoint-medium-max:                           ($breakpoint-large - 1) !default;
$breakpoint-large-max:                            ($breakpoint-xlarge - 1) !default;


// Global variables
// ========================================================================

// Typography

$global-font-family:                              "Roc Grotesk",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$global-font-size:                                18px !default;
$global-line-height:                              1.667 !default;    // 30px

$global-3xlarge-font-size:                        2.5rem !default;   // 45x
$global-2xlarge-font-size:                        1.778rem !default; // 32px
$global-xlarge-font-size:                         1.667rem !default; // 30px
$global-large-font-size:                          1.222rem !default; // 22px
$global-medium-font-size:                         1.111rem !default; // 20px
$global-small-font-size:                          .889rem !default;  // 16px

// Colors

$global-color:                                    base.$color-tertiary !default;
$global-emphasis-color:                           base.$color-emphasis !default;
$global-muted-color:                              base.$color-muted !default;
$global-link-color:                               base.$color-primary !default;
$global-link-hover-color:                         color.adjust(base.$color-primary, $lightness: -10%) !default;
$global-inverse-color:                            base.$color-white !default;

// Backgrounds

$global-background:                               base.$color-white !default;
$global-muted-background:                         color.adjust(base.$color-muted, $lightness: 37.25%) !default;
$global-primary-background:                       base.$color-primary !default;
$global-secondary-background:                     base.$color-secondary !default;
$global-success-background:                       base.$color-success !default;
$global-warning-background:                       base.$color-warning !default;
$global-danger-background:                        base.$color-danger !default;

// Borders

$global-border-width:                             1px !default;
$global-border:                                   base.$color-border !default;

// Box-Shadows

$global-small-box-shadow:                         0 2px 8px rgba(0 0 0 / 8%) !default;
$global-medium-box-shadow:                        0 5px 15px rgba(0 0 0 / 8%) !default;
$global-large-box-shadow:                         0 14px 25px rgba(0 0 0 / 16%) !default;
$global-xlarge-box-shadow:                        0 28px 50px rgba(0 0 0 / 16%) !default;

// Spacings

// Used in margin, section, list
$global-margin:                                   20px !default;
$global-small-margin:                             10px !default;
$global-smedium-margin:                           30px !default;
$global-medium-margin:                            40px !default;
$global-large-margin:                             50px !default;
$global-xlarge-margin:                            80px !default;
$global-xxlarge-margin:                           150px !default;

// Used in grid, column, container, align, card, padding
$global-gutter:                                   40px !default;
$global-small-gutter:                             30px !default;
$global-medium-gutter:                            50px !default;
$global-large-gutter:                             80px !default;

// Controls

$global-control-height:                           40px !default;
$global-control-small-height:                     30px !default;
$global-control-large-height:                     55px !default;

// Z-index

$global-z-index:                                  1000 !default;


// Overwrites
// ========================================================================

// Accordion
$accordion-title-color:                           base.$color-primary;
$accordion-title-hover-color:                     base.$color-primary;
$accordion-title-font-size:                       $global-small-font-size;

// Base

$base-body-background:                            $global-color;
$base-heading-font-weight:                        base.$font-weight-medium;
$base-heading-color:                              $global-color;
$base-h1-font-size-m:                             $global-3xlarge-font-size;
$base-h1-line-height:                             1.2222; // 55px
$base-h1-font-size:                               1.5556rem;
$base-h2-font-size-m:                             $global-2xlarge-font-size;
$base-h2-font-size:                               $global-medium-font-size;
$base-h2-line-height:                             1.375; // 44px
$base-h3-font-size:                               $global-medium-font-size;
$base-h3-font-size-m:                             $global-xlarge-font-size;
$base-h3-line-height:                             1.3333; // 40px
$base-h4-font-size:                               $global-medium-font-size;
$base-h4-line-height:                             1.4; // 28px
$base-link-color:                                 $global-color;
$base-link-hover-color:                           $global-color;
$base-hr-border:                                  $global-color;
$base-hr-margin-vertical:                         $global-medium-margin;

// Background
$background-muted-background:                     base.$color-border;

// Button
$button-primary-background:                       $global-color;
$button-primary-hover-background:                 base.$color-emphasis;
$button-primary-active-background:                base.$color-emphasis;
$button-secondary-color:                          $global-color;
$button-secondary-hover-color:                    $global-color;
$button-secondary-background:                     base.$color-border;
$button-secondary-hover-background:               base.$color-muted;
$button-secondary-active-background:              base.$color-muted;
$button-secondary-active-color:                   $global-color;
$button-disabled-background:                      base.$color-emphasis;
$button-disabled-color:                           base.$color-global;
$button-default-color:                            $global-color;
$button-default-hover-color:                      $global-color;
$button-default-background:                       base.$color-border;
$button-default-hover-background:                 base.$color-muted;
$button-default-active-background:                base.$color-muted;

// Breadcrumb
$breadcrumb-divider-margin-horizontal:            10px;
$breadcrumb-item-color:                           base.$color-emphasis-light;

// Card
$card-badge-top:                                  20px;
$card-badge-right:                                20px;
$card-badge-font-size:                            $global-small-font-size;
$card-badge-background:                           $global-color;
$card-default-background:                         base.$color-border;
$card-secondary-color:                            $global-color;
$card-default-title-color:                        base.$color-primary;
$card-primary-title-color:                        base.$color-secondary;
$card-secondary-title-color:                      base.$color-primary;
$card-secondary-color-mode:                       dark;
$card-body-padding-vertical-l:                    $global-small-gutter;
$card-body-padding-horizontal-l:                  $global-small-gutter;
$card-title-font-size:                            $global-medium-font-size;

// Close
$close-color:                                     $global-color;
$close-hover-color:                               $global-color;

// Container

$container-xlarge-max-width:                      1820px;
$container-large-max-width:                       1620px;
$container-medium-max-width:                      1420px;
$container-small-max-width:                       1000px;

// Dropdown
$dropdown-nav-item-color:                         $global-color;

// Form
$form-height:                                     50px;
$form-legend-font-size:                           $global-small-font-size;

// Grid
$grid-gutter-horizontal-l:                        $global-gutter;

// Heading
$heading-large-font-size-l:                       4.45rem; // 80px
$heading-large-font-size-m:                       3rem;
$heading-large-font-size:                         2rem;
$heading-large-line-height:                       4.72rem;
$heading-large-line-height-l:                     3rem;
$heading-large-line-height-m:                     2rem;

// Height
$height-large-height:                             600px;

// Icon
$icon-button-background:                          rgba(base.$color-white, 20%);
$icon-button-color:                               base.$color-white;
$icon-button-hover-color:                         color.adjust(base.$color-white, $lightness: -10%);
$inverse-icon-button-color:                       $global-color;
$inverse-icon-button-background:                  base.$color-border;
$inverse-icon-button-hover-color:                 base.$color-tertiary;
$inverse-icon-button-hover-background:            color.adjust(base.$color-border, $lightness: -10%);

// Iconnav
$iconnav-item-color:                              $global-color;

// Inverse
$inverse-global-color:                            base.$color-white;

// Internal
$internal-accordion-close-image:                  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik00ODAtMzMzIDI0MC01NzNsNTEtNTEgMTg5IDE4OSAxODktMTg5IDUxIDUxLTI0MCAyNDBaIi8+PC9zdmc+";
$internal-accordion-open-image:                   "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Im0yOTEtMzM2LTUxLTUxIDI0MC0yNDAgMjQwIDI0MC01MSA1MS0xODktMTg5LTE4OSAxODlaIi8+PC9zdmc+";


// Link
$inverse-link-text-hover-color:                   base.$color-white;

// List
$list-divider-border:                             $global-color;

// Modal
$modal-dialog-width:                              800px;
$modal-body-padding-vertical-s:                   80px;
$modal-body-padding-horizontal-s:                 100px;
$modal-body-padding-vertical:                     20px;
$modal-body-padding-horizontal:                   20px;
$modal-close-position:                            20px;

// Nav
$nav-primary-font-size:                           35px;
$nav-primary-item-color:                          $global-color;
$nav-primary-item-hover-color:                    $global-color;
$nav-primary-item-active-color:                   $global-color;
$nav-secondary-item-color:                        $global-color;
$nav-secondary-item-hover-color:                  $global-color;
$nav-default-font-size:                           $global-xlarge-font-size;
$nav-default-item-color:                          $global-color;
$nav-default-item-hover-color:                    $global-color;

// Navbar
$navbar-nav-gap:                                  $global-gutter;
$navbar-background:                               base.$color-tertiary;
$navbar-nav-item-height:                          100px;
$navbar-nav-item-color:                           base.$color-emphasis;
$navbar-nav-item-active-color:                    base.$color-white;
$navbar-nav-item-onclick-color:                   base.$color-white;
$navbar-nav-item-hover-color:                     base.$color-white;
$navbar-toggle-hover-color:                       base.$color-white;

// $navbar-background:                               transparent;
// Off-Canvas

$offcanvas-bar-width-s:                           460px;
$offcanvas-z-index:                               2;
$offcanvas-overlay-background:                    rgba(0 0 0 / 70%);
$offcanvas-bar-color-mode:                        dark;
$offcanvas-close-position-s:                      $global-medium-gutter;
$offcanvas-close-position:                        $global-gutter;

// Overlay
$overlay-default-background:                     rgba(0 0 0 / 50%);

// Padding
$padding-large-padding-l:                         $global-large-gutter;
$padding-large-padding:                           $global-margin;

// Pagination
$pagination-item-color:                           base.$color-global;

// Position
$position-large-margin-l:                         $global-large-gutter;
$position-small-margin:                           $global-margin;

// Section
$section-secondary-color-mode:                    dark;
$section-default-background:                      base.$color-white;

// Text
$text-lead-color:                                 base.$color-tertiary;
$text-small-line-height:                          1.4; // 1.625;
$text-large-line-height:                          1.638;
$text-meta-color:                                 base.$color-tertiary;
$text-meta-font-size:                             .7778rem;  // 14px
$text-meta-line-height:                           1.7143;
$text-lead-font-size:                             $global-xlarge-font-size;
$text-lead-line-height:                           1.4;
$text-emphasis-color:                             base.$color-emphasis-light;
$text-muted-color:                                base.$color-emphasis-light;

// Tooltip
$tooltip-max-width:                               400px;

// Width
$width-large-width:                               420px;
