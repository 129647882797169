.tm-image-overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent linear-gradient(180deg, #0000 0%, #000 100%) 0 0 no-repeat padding-box;
  opacity: .6;
}

.webcare-youtube {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
