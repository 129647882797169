@use "sass:color";
@use "sass:math";
@use "@theme/scss/utils/variables" as base;
@use "@/scss/utils/variables" as default;

// Global variables
// ========================================================================

// Colors

$global-link-color:                               base.$color-primary-dark !default;
$global-link-hover-color:                         color.adjust(base.$color-primary-dark, $lightness: -10%) !default;

// Base

$base-body-background:                            default.$color-white;
$base-link-hover-text-decoration:                 none;

// Backgrounds

$global-primary-background:                       base.$color-primary-dark;

// Icon

$icon-button-background:                          default.$color-border;
$icon-button-color:                               default.$color-tertiary;
$icon-button-hover-color:                         default.$color-tertiary;

// Link
$link-text-hover-color:                           default.$color-tertiary;
$inverse-link-text-hover-color:                   default.$color-white;

// List
$list-large-divider-margin-top:                   $global-small-gutter;

// Nav
$nav-primary-font-size:                           $global-medium-font-size;

// Navbar

$navbar-background:                               default.$color-white;
$navbar-nav-item-color:                           default.$color-tertiary;
$navbar-nav-item-hover-color:                     default.$color-tertiary;
$navbar-nav-item-onclick-color:                   default.$color-tertiary;
$navbar-nav-item-active-color:                    default.$color-tertiary;
$navbar-nav-gap-m:                                50px;

// Text

$inverse-text-lead-color:                         default.$color-white;
