.tm-contact-address {
  padding: 185px $global-gutter 185px 200px;

  @media (max-width: $breakpoint-medium-max) {
    padding: $global-large-gutter;
  }

  @media (max-width: $breakpoint-small-max) {
    padding: $global-small-gutter;
  }
}
