/* stylelint-disable declaration-colon-space-after */

@font-face {
  font-family:"Roc Grotesk";
  src:
    url("@/fonts/923e16a8-c57d-4f97-92d8-0d4aca56b180.woff2") format("woff2"),
    url("@/fonts/ade876eb-5c42-4c95-9ce8-1641a11d556d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family:"Roc Grotesk";
  src:
    url("@/fonts/1bc155e8-cd20-49ce-8eb8-1bcea5378124.woff2") format("woff2"),
    url("@/fonts/1b2ef3e5-b107-4790-bee2-6cda85dc3231.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family:"Roc Grotesk";
  src:
    url("@/fonts/69eaf6d4-d982-4623-b025-832b8aa7038b.woff2") format("woff2"),
    url("@/fonts/0c5abe0a-966b-4ab2-bc39-62d76c90c2a6.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
