@use "@/scss/base/uikit/uikit-variables" as uikit;
@use "@/scss/utils/variables" as base;

.tm-disturber {
  width: 356px;

  &::before {
    content: "";
    position: absolute;
    width: 256px;
  }

  &::after {
    content: "";
    position: absolute;
  }
}

.tm-disturber-bottom-left {
  @media (min-width: uikit.$breakpoint-medium) {

    &::before {
      left: 0;
      top: 100px;
      bottom: 0;
      height: 50px;
      width: 256px;
      background: #fff;
    }

    &::after {
      top: 50px;
      bottom: 0;
      left: 256px;
      width: 50px;
      height: 50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid #fff;
    }
  }

  @media (max-width: uikit.$breakpoint-medium-max) {

    &::before {
      left: 0;
      top: 70px;
      bottom: 0;
      width: 136px;
      height: 50px;
      background: #fff;
    }

    &::after {
      top: 20px;
      bottom: 0;
      left: 136px;
      width: 50px;
      height: 50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid #fff;
    }
  }
}

.tm-disturber-bottom-right {
  @media (min-width: uikit.$breakpoint-medium) {

    &::before {
      right: 0;
      bottom: 0;
      height: 50px;
      background: #fff;
      width: 206px;
    }

    &::after {
      bottom: 0;
      right: 206px;
      width: 25px;
      border-top: 50px solid transparent;
      border-right: 50px solid #fff;
    }
  }

  @media (max-width: uikit.$breakpoint-medium-max) {

    &::before {
      right: 0;
      bottom: 0;
      height: 30px;
      background: #fff;
      width: 106px;
    }

    &::after {
      bottom: 0;
      right: 106px;
      width: 15px;
      border-top: 30px solid transparent;
      border-right: 30px solid #fff;
    }
  }
}

.tm-disturber-top-left {
  @media (min-width: uikit.$breakpoint-medium) {

    &::before {
      left: 0;
      top: 0;
      height: 50px;
      width: 256px;
      background: #fff;
    }

    &::after {
      top: -50px;
      bottom: 0;
      left: 256px;
      width: 50px;
      height: 50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid #fff;
    }
  }

  @media (max-width: uikit.$breakpoint-medium-max) {

    &::before:not(.tm-disturber-navigation) {
      left: 0;
      top: 0;
      bottom: 0;
      width: 136px;
      height: 50px;
      background: #fff;
    }

    &::after(.tm-disturber-navigation) {
      top: -50px;
      bottom: 0;
      left: 136px;
      width: 50px;
      height: 50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid #fff;
    }
  }
}

.tm-disturber-navigation {

  &::before {
    left: 0;
    top: 0;
    height: 70px;
    width: 156px;
    background: #fff;
  }

  &::after {
    top: -70px;
    bottom: 0;
    left: 156px;
    width: 70px;
    height: 70px;
    border-bottom: 70px solid transparent;
    border-left: 70px solid #fff;
  }
}

.tm-disturber-footer {
  width: 356px;

  &::before {
    content: "";
    position: absolute;
    width: 256px;
    top: -50px;
    height: 50px;
    left: 0;
    background: base.$color-secondary;
  }

  &::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    top: -50px;
    left: 256px;
    border-top: 50px solid transparent;
    border-left: 50px solid base.$color-secondary;
  }
}

.tm-disturber-navigation-black {

  &::before {
    left: 0;
    top: 0;
    height: 50px;
    width: 156px;
    background: base.$color-tertiary;
  }

  &::after {
    top: -50px;
    bottom: 0;
    left: 156px;
    width: 50px;
    height: 50px;
    border-bottom: 50px solid transparent;
    border-left: 50px solid base.$color-tertiary;;
  }
}


.tm-disturber-mobile {
  @media (max-width: uikit.$breakpoint-medium-max) {

    &::before {
      left: 0;
      top: 0;
      height: 50px;
      width: 156px;
      background: base.$color-white;
    }

    &::after {
      top: -50px;
      bottom: 0;
      left: 156px;
      width: 50px;
      height: 50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid base.$color-white;;
    }
  }
}

.tm-disturber-z-index {

  &::after,
  &::before {
    z-index: 1;
  }
}
