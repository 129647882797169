/* stylelint-disable declaration-colon-space-after, no-descending-specificity, selector-class-pattern */

/*! purgecss start ignore */

/* CSS variables */
.ais-ColorRefinementList {
  // Transition
  --transition-duration: .2s;

  // Grid
  --items-column-width: 3rem;
  --items-gap: 1rem;

  // Refined icon
  --refined-icon-size: 1.1rem;

  // Color
  --color-size: 1.75rem;
}

/* ---------- Items */
.ais-ColorRefinementList-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--items-column-width), 1fr));
  gap: var(--items-gap);
}

.ais-ColorRefinementList-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .8rem;
  color: black;

  /* Reset button styles */
  font-family: inherit;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;

  /* Hover/active states */
  &:hover .ais-ColorRefinementList-color {
    transform: scale(1.1);
  }

  &:active .ais-ColorRefinementList-color {
    transform: scale(.9);
  }
}

/* ---------- Color */
.ais-ColorRefinementList-color {
  width: var(--color-size);
  height: var(--color-size);
  border-radius: 9999px;
  transition: var(--transition-duration) transform ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

/* Specific case: Add border to color circle to distinguish it from the background */
.ais-ColorRefinementList-color.color--ffffffff {
  border: 1px solid #d1d1d1;
}

/* Shape modifiers */
.ais-ColorRefinementList-shapeSquare .ais-ColorRefinementList-color {
  border-radius: 0;
}

/* ---------- Label */
.ais-ColorRefinementList-label {
  margin-top: .3rem;
  transition: .3s color ease-out;
  padding: 0 .3rem;
}

.ais-ColorRefinementList-item:hover .ais-ColorRefinementList-label {
  color: #848484;
}

/* ---------- Count */
.ais-ColorRefinementList-count {
  display: none;
}

/* ---------- RefinedIcon */
.ais-ColorRefinementList-refinedIcon {
  position: relative;
  width: var(--refined-icon-size);
  height: var(--refined-icon-size);
  color: var(--contrast-color);
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  transition:
    transform var(--transition-duration) ease-out 0s,
    opacity var(--transition-duration) linear 0s,
    visibility 0s linear var(--transition-duration);
}

.ais-ColorRefinementList-item.ais-ColorRefinementList-itemRefined .ais-ColorRefinementList-refinedIcon {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  transition:
    transform var(--transition-duration) ease-out 0s,
    opacity var(--transition-duration) linear 0s,
    visibility 0s linear 0s;
}

/*! purgecss end ignore */
