@use "@/scss/base/uikit/uikit-variables" as uikit;
@use "@/scss/utils/variables" as base;

.tm-left-header {
  margin-bottom: uikit.$global-gutter;

  @media (max-width: uikit.$breakpoint-medium-max) {
    margin: 0;
  }
}

.tm-right-header {
  margin-top: uikit.$global-gutter;

  @media (max-width: uikit.$breakpoint-medium-max) {
    margin: 0;
  }
}

.tm-padding-small {
  @media (max-width: uikit.$breakpoint-medium-max) {
    padding: 100px 20px 50px;
  }
}

#mobile-navigation {
  @media (max-width: uikit.$breakpoint-large-max) {
    background: base.$color-secondary;
    z-index: 10000;

    .uk-offcanvas-close {
      right: 20px;
      padding: 0;
      top: 30px;
      background: transparent;
    }

    .tm-disturber {
      position: absolute;
      z-index: 10000000;
    }

    .tm-disturber > img {
      z-index: 10000001;
      position: absolute;
      left: 16px;
      top: 18px;
    }

    .tm-disturber::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 156px;
      height: 70px;
      background: uikit.$global-background;
    }

    .tm-disturber::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;
      height: 0;
      left: 156px;
      border-bottom: 70px solid transparent;
      border-left: 70px solid uikit.$global-background;
    }

  }
}
