// Breakpoints

$sm: 640px !default;                                                            // default breakpoints
$md: 960px !default;
$lg: 1200px !default;
$xl: 1600px !default;


// Colors
$color-primary:        #0d616f !default;                                        // CI
$color-camouflage:     #6f7663 !default;                                        // CI
$color-camoutech:      #1a412c !default;                                        // CI
$color-secondary:      #d6da2d !default;
$color-tertiary:       #000 !default;                                           // Black and white
$color-emphasis:       #575757 !default;
$color-emphasis-light: #767676 !default;
$color-global:         #a5a5a5 !default;
$color-global-light:   #cbcbcb !default;
$color-muted:          #e2e2e2 !default;
$color-border:         #f0f0f0 !default;
$color-white:          #fff !default;
$color-success:        #32cd32 !default;                                        // Success, warning and danger
$color-warning:        #ffa500 !default;
$color-danger:         #f00000 !default;
$color-secondary-dark:     #bcbf22 !default;
$color-secondary-light:    #e6e981 !default;
$color-secondary-lighter:  #f3f4bf !default;
$color-secondary-lightest: #fbfbe9 !default;

// Font Weights
$font-weight-regular:  400 !default;
$font-weight-medium:   500 !default;
$font-weight-bold:     700 !default;
