@use "@/scss/utils/variables" as base;
@use "uikit-variables" as uikit;

// Accordion
@mixin hook-accordion() {

  .uk-open a {
    border: unset;
  }

  .uk-open {

    .uk-accordion-content {
      border-bottom: 1px solid uikit.$global-color;
      padding-bottom: uikit.$global-gutter;
    }
  }
}

@mixin hook-accordion-title() {
  border-bottom: 1px solid black;
  font-weight: base.$font-weight-bold;
  padding: uikit.$global-margin 0;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    margin-left: $accordion-icon-margin-left;
    float: right;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include svg-fill($internal-accordion-close-image, "#000", $accordion-icon-color);
  }

  .uk-open > &::before {
    @include svg-fill($internal-accordion-open-image, "#000", $accordion-icon-color);
  }
}

@mixin hook-accordion-title-hover() {
  border-bottom: 1px solid black;
}

@mixin hook-accordion-item() {
  margin: 0;
}

// Base
@mixin hook-base-body() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

@mixin hook-base-h3() {
  font-weight: base.$font-weight-bold;
}

@mixin hook-base-h4() {
  font-weight: base.$font-weight-bold;
}

@mixin hook-base-misc {
  @media (min-width: $breakpoint-medium) {

    h3,
    .uk-h3 {
      font-size: $base-h3-font-size-m;
    }
  }
}

// Badge
@mixin hook-badge-misc {

  .tm-product-badge {
    background: base.$color-tertiary;
    font-weight: base.$font-weight-medium;
    font-size: uikit.$global-small-font-size;
    padding: 0 10px;
    height: 25px;
  }
}

// Button
@mixin hook-button() {
  border-radius: 20px;
  text-transform: none;
  font-weight: base.$font-weight-medium;
}

@mixin hook-button-default() {
  border: unset;
}

// Breadcrumb
@mixin hook-breadcrumb-item {
  font-weight: base.$font-weight-medium;
}

// Card
@mixin hook-card() {

  &.tm-card-search {
    border: 1px solid base.$color-global;
    background: base.$color-white;
  }

  .tm-card-icon {
    color: base.$color-secondary;
  }
}

@mixin hook-card-title {

  * {
    font-weight: base.$font-weight-bold !important;
  }
}

@mixin hook-card-primary() {

  .uk-card-title {
    color: base.$color-secondary !important;
  }
}

@mixin hook-card-secondary() {

  .tm-card-icon {
    color: base.$color-primary;
  }
}

@mixin hook-card-default() {
  box-shadow: none;
}


@mixin hook-card-badge() {
  right: unset;
  left: $card-badge-right;
  font-weight: base.$font-weight-medium;
  border-radius: 20px;
  z-index: 0;
}


@mixin hook-card-media() {

  .tm-card-overlay::before {
    content: "";
    position: absolute;
    inset: 0;
    background: transparent linear-gradient(180deg, #0000 0%, #000 100%) 0 0 no-repeat padding-box;
    opacity: .6;
  }
}

@mixin hook-card-misc {

  .tm-product-card h3 > span {
    font-size: uikit.$global-medium-font-size !important;
  }
}

// Close
@mixin hook-close() {
  transition: .2s ease-in-out;
  transition-property: color, opacity;

  &:not(.uk-lightbox-toolbar-icon) {
    background: base.$color-white;
  }

}

// Container
@mixin hook-container-misc() {

  .uk-container-medium {
    max-width: uikit.$container-medium-max-width;
  }

  .tm-margin-left {
    margin-left: 200px;

    // margin-left: 200px;
    @media (max-width: $breakpoint-medium-max) {
      margin-left: 0;
    }

    @media (max-width: $breakpoint-small-max) {
      margin-left: 0;
    }
  }

  .tm-margin-right {
    margin-right: 200px;

    @media (max-width: $breakpoint-medium-max) {
      margin-right: 0;
    }

    @media (max-width: $breakpoint-small-max) {
      margin-right: 0;
    }
  }
}

// Dropdown
@mixin hook-dropdown-nav-item-hover {
  font-weight: base.$font-weight-bold;
}

// Form
@mixin hook-form() {
  font-size: uikit.$global-small-font-size;
  border: 1px solid base.$color-global-light;
  margin-top: uikit.$global-small-margin;

  &:valid:not(.tm-shop-select) {
    border: 1px solid uikit.$global-color;
  }

  &::placeholder {
    color: black;
  }
}

@mixin hook-form-label() {
  color: base.$color-primary;
  font-weight: base.$font-weight-bold;
  font-size: uikit.$global-small-font-size;

  &.required::after { content: "*"; }
}

@mixin hook-form-focus() {
  border: 2px solid uikit.$global-color;
}

@mixin hook-form-single-line() {
  padding: 0 15px;

  &::placeholder {
    color: black;
  }
}

// Grid
@mixin hook-grid-misc {

  .tm-products-grid > .uk-grid-margin {
    margin-top: 100px;

    @media (max-width: $breakpoint-small-max) {
      margin-top: 40px;
    }
  }
}

// Heading
@mixin hook-heading-large() {
  font-weight: base.$font-weight-medium;

  @media (max-width: $breakpoint-small-max) {
    line-height: 3rem;
  }
}

// Icon
@mixin hook-icon-button() {

  .tm-icon-dark {
    background: uikit.$global-color;
    color: base.$color-white;
  }

  &.tm-icon-light {
    background: base.$color-white;
    color: base.$color-tertiary;
  }

  &.tm-icon-primary {
    background: base.$color-primary;
    color: base.$color-white;
  }

  &.tm-icon-button-dark {
    color: base.$color-white;
    background: base.$color-tertiary;
  }
}

@mixin hook-inverse-icon-button {

  &.tm-icon-light {
    background: base.$color-white !important;
    color: base.$color-tertiary;
  }

  &.tm-icon-dark {
    background: uikit.$global-color;
    color: base.$color-white;
  }

  &.tm-icon-secondary {
    background: base.$color-secondary;
    color: base.$color-tertiary;
  }
}

@mixin hook-inverse-icon-button-hover {

  &.tm-icon-secondary {
    background: base.$color-secondary;
    color: base.$color-tertiary;
  }
}

@mixin hook-icon-misc {

  .uk-icon-button-large {
    width: 42px;
    height: 42px;
  }
}

// Iconnav
@mixin hook-iconnav() {

  &.tm-iconnav-mobile {
    gap: 10px;
    color: uikit.$global-color;
  }
}

// Inverse
@mixin hook-inverse-component-text() {

  .uk-text-lead {
    color: $inverse-text-lead-color;
  }

  .uk-text-meta {
    color: $inverse-text-meta-color;
  }

  .uk-text-muted {
    color: $inverse-text-muted-color !important;
  }

  .uk-text-emphasis {
    color: $inverse-text-emphasis-color !important;
  }
}

// Legend
@mixin hook-form-legend() {
  font-weight: base.$font-weight-bold;
  margin-bottom: uikit.$global-small-margin;
}

// Link
@mixin hook-base-link-hover() {
  text-decoration: underline;
}

@mixin hook-link-text() {
  font-size: uikit.$global-font-size;
  font-weight: base.$font-weight-medium;
  line-height: 1.6rem;

  &::before {
    content: "";
  }
}

@mixin hook-link-text-hover {
  position: relative;
  text-decoration: none;

  &::before,
  > .uk-text-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    background-color: base.$color-tertiary;
    transition: all .3s ease-out 0s;
  }
}

@mixin hook-inverse-link-text-hover() {

  &::before {
    background-color: base.$color-white;
  }
}

@mixin hook-link-misc {

  .uk-link-toggle:hover {

    > .tm-icon-toggle {
      background: base.$color-muted;
    }
  }
}

// List
@mixin hook-list-misc() {

  .tm-list-xlarge > :nth-child(n+2) {
    margin-top: 60px;
    padding-top: 60px;

    @media (max-width: $breakpoint-small-max) {
      margin-top: 30px;
      padding-top: 30px;
      margin-bottom: 0;
    }
  }
}

@mixin hook-list-divider() {

  &.tm-downloads {
    margin-top: 20px !important;
    padding-top: 20px !important;
    border-color: base.$color-border !important;
    border-width: 2px !important;
  }
}

// Marker
@mixin hook-marker() {

  &:hover {
    transform: scale(160%);
  }
}

@mixin hook-modal-close {
  background: base.$color-border !important;
}

// Nav
@mixin hook-nav-primary() {
  font-weight: base.$font-weight-medium;

  span {
    border-bottom: 1px solid transparent;
  }
}

@mixin hook-nav-primary-item-hover() {
  background: unset;

  > span {
    border-bottom: 1px solid uikit.$global-color;
  }
}

@mixin hook-nav-default() {
  font-weight: base.$font-weight-bold;
}

@mixin hook-nav-secondary-item() {
  font-weight: base.$font-weight-medium;
  font-size: uikit.$global-small-font-size;
  padding: uikit.$global-small-margin 0;

  > span {
    border-bottom: 1px solid transparent;
  }
}

@mixin hook-nav-secondary-item-hover() {
  background: unset;

  > span {
    border-bottom: 1px solid uikit.$global-color;
  }
}

// Navbar
@mixin hook-navbar() {

  .uk-navbar-nav.tm-navbar-language {
    gap: 10px;

    li:not(.uk-active) a {
      color: base.$color-emphasis-light;

      &:hover {
        color: base.$color-global;
      }
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@mixin hook-navbar-item() {
  @media (max-width: $breakpoint-small-max) {
    min-height: 70px !important;
    height: 70px !important;
  }
}

@mixin hook-navbar-nav-item() {
  @media (max-width: $breakpoint-small-max) {
    min-height: 70px !important;
    height: 70px !important;
  }
}

@mixin hook-navbar-transparent() {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 256px;
    background: uikit.$global-background;

    @media (max-width: $breakpoint-small-max) {
      width: 156px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 256px;
    width: 0;
    height: 0;
    border-bottom: 100px solid transparent;
    border-left: 100px solid uikit.$global-background;

    @media (max-width: $breakpoint-small-max) {
      left: 156px;
      border-bottom: 70px solid transparent;
      border-left: 70px solid uikit.$global-background;
    }
  }
}

// Off-canvas

@mixin hook-offcanvas-misc() {

  .tm-mobile-shop-navigation {
    background: base.$color-secondary-light;
  }

  .tm-second-offcanvas {
    left: 0;
    transition: left .3s ease-in;

    &.uk-active {
      left: calc(2 * uikit.$offcanvas-bar-width-s);
      display: block;
      transition: left .3s ease-in;
    }

    .tm-shop-navigation {
      background: base.$color-secondary-light;
    }
  }
}

@mixin hook-offcanvas-bar() {

  &.tm-main {
    @media (max-width: uikit.$breakpoint-medium) {
      width: 100%;

      &::before,
      &::after {
        content: unset;
      }
    }

    @media (min-width: uikit.$breakpoint-medium) {
      top: 100px
    }
  }

  &.tm-shop-navigation {
    top: uikit.$navbar-nav-item-height;
  }

  &.tm-mobile-navigation::before {
    background: base.$color-white;
    height: 70px;
    width: 256px;
  }

  &.tm-mobile-navigation::after {
    left: 256px;
    border-bottom: 70px solid transparent;
    border-left: 70px solid base.$color-white;
  }
}

@mixin hook-offcanvas-close() {
  @media (max-width: $breakpoint-small-max) {
    top: 20px;
    right: 30px;
  }
}

// Pagination
@mixin hook-pagination() {

  .uk-icon-button {
    background: base.$color-tertiary;
    color: base.$color-white;
  }

  .uk-disabled .uk-icon-button {
    background: base.$color-border;
    color: base.$color-tertiary;
  }

  a {
    text-decoration: none;
  }
}

@mixin hook-pagination-item-active {
  font-weight: base.$font-weight-medium;
}

// Section
@mixin hook-section-misc() {

  .tm-section-footer {
    @media (max-width: uikit.$breakpoint-medium-max) {
      background: base.$color-white !important;

      .uk-container {
        padding: 0;
      }

      .uk-container > .uk-grid > div {
        margin: 0 !important;
      }

      .uk-container > .uk-grid > div > div {
        padding: uikit.$global-small-gutter;
      }
    }
  }
}

// Slidenav
@mixin hook-slidenav-container() {

  .uk-invisible {
    visibility: visible !important;
    background: base.$color-border;
    color: base.$color-tertiary;
    cursor: default;

    &:hover,
    &:active {
      background: base.$color-muted;
      color: base.$color-tertiary;
    }
  }
}

// Text
@mixin hook-text-lead() {
  font-weight: base.$font-weight-medium;

  @media (max-width: $breakpoint-medium-max) {
    font-size: uikit.$global-medium-font-size;
  }
}

@mixin hook-text-misc() {

  .uk-text-medium {
    font-weight: base.$font-weight-medium;
  }
}

// Thumbnav
@mixin hook-thumbnav {
  gap: 10px;
  flex-wrap: nowrap;

  > * {
    width: 22%;
    padding-left: 0 !important;
  }
}

@mixin hook-thumbnav-item-active {
  border: 2px solid black;
}

// Width
@mixin hook-width-misc {

  .tm-card-employee {
    @media (min-width: $breakpoint-medium) {
      width: 420px;
    }
  }
}
