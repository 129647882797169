/* stylelint-disable */

/* purgecss start ignore */
@import '../utils/variables';

// Fonts
$font-family-base: 'Roc Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !default;

// Colors
$color-black: $color-tertiary !default;
$color-gray: $color-emphasis !default;
$color-red: $color-danger !default;
$color-green: $color-success !default;
$color-orange: $color-warning !default;
$color-white: $color-white !default;
$color-almost-white: $color-white !default;
$color-asphalt-gray: $color-primary !default;

@import "~@uppy/core/src/style";
@import "~@uppy/dashboard/src/style";
.uppy-size--md .uppy-Dashboard-Item {
  float: left !important;
}

input::placeholder {
  color: #a5a5a5 !important;
}
/* purgecss end ignore */
